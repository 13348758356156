import React from "react"
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Fab,
  Box,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useTranslation } from "react-i18next"

import TablePaginationActions from "../../../components/Pagination/TablePageActions"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 4px",
    "& .MuiTableHead-root": {
      borderBottom: "1px solid #757575",
    },
  },
  thead: {
    "& th": {
      color: "#757575",
    },
  },
  rankIndexBtn: {
    width: "35px !important",
    height: "35px !important",
    background: "rgba(215 227 232) !important",
    "& span": {
      color: "black",
    },
  },
}))

const LeaderboardList = ({
  leaderboard,
  pageNumber,
  itemsPerPage,
  handleChangePageNumber,
  handleChangeItemsPerPage,
  isLoading,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const getRankIndex = (index) => {
    if (!isLoading) {
      const skip = pageNumber * itemsPerPage
      return skip + index + 1
    }
  }

  const LeaderBoardList = () => {
    return (
      <TableContainer>
        <Table className={classes.table} aria-label="collapsible table">
          <TableHead className={classes.thead}>
            <TableRow>
              <TableCell>{t("rank")}</TableCell>
              <TableCell>{t("salesPerson")}</TableCell>
              <TableCell>{t("totalContract")}</TableCell>
              <TableCell>{t("points")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tbody}>
            {leaderboard?.data?.map((item, index) => (
              <TableRow key={`lb#${index}`}>
                <TableCell>
                  <Fab
                    size="small"
                    className={classes.rankIndexBtn}
                    component="div"
                    disabled
                  >
                    <span>{getRankIndex(index)}</span>
                  </Fab>
                </TableCell>
                <TableCell>{item.salePerson}</TableCell>
                <TableCell>{item.totalContracts}</TableCell>
                <TableCell>{item.totalPoints}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={8}
                page={pageNumber}
                count={leaderboard?.totalItems}
                rowsPerPageOptions={[10, 25, 50]}
                rowsPerPage={itemsPerPage}
                onPageChange={(event, newPage) => {
                  handleChangePageNumber(newPage)
                }}
                onRowsPerPageChange={(event) => {
                  const newItemsPerPage = event.target.value
                  handleChangePageNumber(0)
                  handleChangeItemsPerPage(newItemsPerPage)
                }}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    )
  }

  const DataNotFound = () => {
    return (
      <Box sx={{ width: "100%", textAlign: "center" }}>
        <Typography variant="span" className={classes.headerText}>
          {t("noDataAvail")}
        </Typography>
      </Box>
    )
  }

  return (
    <div className={classes.root}>
      {leaderboard?.data?.length > 0 ? <LeaderBoardList /> : <DataNotFound />}
    </div>
  )
}

export default LeaderboardList
