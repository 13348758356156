import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Container, Paper } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { get } from "lodash"
import { useTranslation } from "react-i18next"

import RejectionSuccess from "./components/RejectionSuccess"
import RejectionForm from "./components/RejectionForm"
import RejectionLoading from "./components/RejectionLoading"
import RejectionError from "./components/RejectionError"
import RewardHeader from "../../components/RewardHeader"
import { rejectUser, getProfileByRejectionToken } from "../../api/rejectUser"

const useStyles = makeStyles((theme) => ({
  rejectionScreen: {
    height: "100vh",
    "&.MuiContainer-root ": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  infoBox: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "&.MuiPaper-root": {
      borderRadius: 20,
      boxShadow: "0px 0px 6px 0px rgb(116 115 115 / 44%)",
    },
  },
}))

const Rejection = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)
  const [userProfile, setUserProfile] = useState({})
  const [userInfo, setUserInfo] = useState({})
  const { token } = useParams()

  useEffect(() => {
    const getProfile = async () => {
      const { error, data } = await getProfileByRejectionToken(token)
      if (error) setError(t("invalidToken"))
      else setUserProfile(data)
    }
    try {
      getProfile()
    } catch {
      setError(true)
    }
  }, [token, t])

  const userFullName = `${get(userProfile, "firstName", "")} ${get(
    userProfile,
    "lastName",
    ""
  )}`

  const handleSubmitForm = async (reason) => {
    setLoading(true)
    const { user, error } = await rejectUser(reason, token)
    if (user) {
      setLoading(false)
      setSuccess(true)
      setUserInfo(user)
    } else setError(error)
  }

  return (
    <Container maxWidth="xs" className={classes.rejectionScreen}>
      <RewardHeader />
      <Paper className={classes.infoBox}>
        {loading && !error && <RejectionLoading />}
        {error && <RejectionError message={error} />}
        {!loading && !error && !success && (
          <RejectionForm
            candidateName={userFullName}
            handleSubmit={handleSubmitForm}
          />
        )}
        {success && <RejectionSuccess userInfo={userInfo} />}
      </Paper>
    </Container>
  )
}

export default Rejection
