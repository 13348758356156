import axios from "axios"

export const rejectUser = (reason, rejectionToken) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

  const rejectionEndpoint = `${API_ENDPOINT}/verifications/reject`

  return axios
    .post(rejectionEndpoint, { reason, token: rejectionToken })
    .then((res) => res?.data?.data)
    .catch((error) => ({ error: error.message }))
}

export const getProfileByRejectionToken = (token) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

  return axios
    .get(`${API_ENDPOINT}/profileByRejectionToken/${token}`)
    .then((res) => {
      return res.data
    })
    .catch((error) => ({ error: error.message }))
}
