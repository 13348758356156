import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  palette: {
    primary: {
      main: "#0066B1",
    },
    secondary: {
      main: "#333333",
    },
  },
  typography: {
    fontFamily: ["BMWTypeNext", "Arial", "Helvetica", "sans-serif"].join(","),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          WebkitFontSmoothing: "auto",
          fontFamily: ["BMWTypeNext", "Arial", "Helvetica", "sans-serif"].join(
            ","
          ),
        },
        body: {
          fontFamily: ["BMWTypeNext", "Arial", "Helvetica", "sans-serif"].join(
            ","
          ),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          boxShadow: "none",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: "6px",
          color: "#333",
          fontSize: "1em",
        },
      },
    },
  },
})

export default theme
