import React from "react"
import useStyles from "../style"
import { Grid, Button, AlertTitle, Alert } from "@mui/material"
import { Formik, Form } from "formik"
import InputField from "../../../components/InputField"
import useDataprovider from "../../../hooks/useDataProvider"
import _ from "lodash"
import { useTranslation } from "react-i18next"

const ChangePasswordForm = (props) => {
  const classes = useStyles()
  const { updatePassword } = useDataprovider()
  const { t } = useTranslation()

  const initialPasswordValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  }

  const validatePassword = (values) => {
    const errors = {}

    if (!values.oldPassword)
      _.set(errors, "oldPassword", `${t("oldPasswordError")}`)
    if (!values.newPassword)
      _.set(errors, "newPassword", `${t("newPasswordError")}`)
    if (!values.confirmPassword)
      _.set(errors, "confirmPassword", `${t("confirmPasswordError")}`)
    if (values.confirmPassword !== values.newPassword)
      _.set(errors, "confirmPassword", `${t("passwordNotMatch")}`)
    return errors
  }

  const onSubmitPassword = async (values) => {
    const submitValues = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    }

    updatePassword(submitValues)
      .then((response) => {
        if (response.success) {
          props.handlePasswordDialogClose()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Formik
      validate={validatePassword}
      onSubmit={onSubmitPassword}
      initialValues={initialPasswordValues}
    >
      {({ errors, values, handleChange }) => (
        <Form>
          <Grid
            container
            className={classes.formContainer}
            style={{ width: 350 }}
          >
            <Grid item xs={12}>
              <div className={classes.textFieldContainer}>
                <InputField
                  name="oldPassword"
                  value={values.oldPassword}
                  label={t("oldPassword")}
                  type="password"
                  onChange={handleChange}
                />
                <InputField
                  name="newPassword"
                  value={values.newPassword}
                  label={t("newPassword")}
                  type="password"
                  onChange={handleChange}
                />
                <InputField
                  name="confirmPassword"
                  value={values.confirmPassword}
                  label={t("confirmPassword")}
                  type="password"
                  onChange={handleChange}
                />
              </div>
              <div>
                {_.size(errors) > 0 && (
                  <Alert severity="error" style={{ marginBottom: 8 }}>
                    <AlertTitle>Error</AlertTitle>
                    <ul style={{ paddingLeft: 0 }}>
                      {_.map(errors, (error, i) => (
                        <li key={`error-${i}`}>{error}</li>
                      ))}
                    </ul>
                  </Alert>
                )}
              </div>
              <Button
                variant="contained"
                className={classes.primaryBtn}
                type="submit"
              >
                {t("saveNewPassword")}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default ChangePasswordForm
