import React from "react"
import { Grid, Typography, CircularProgress } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles(() => ({
  loading: {
    display: "flex",
    justifyContent: "center",
  },
}))

const ApprovalLoading = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">{t("approving")}</Typography>
      </Grid>
      <Grid item xs={12} mt={2} className={classes.loading}>
        <CircularProgress />
      </Grid>
    </Grid>
  )
}

export default ApprovalLoading
