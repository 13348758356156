import React from "react"
import { get } from "lodash"
import { Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const ApprovalSuccess = ({ userInfo, isAdmin = false }) => {
  const { firstName, lastName } = userInfo
  const fullName = `${firstName} ${lastName}`
  const roleName = get(userInfo, "role.name", "")
  const dealershipName = get(userInfo, "dealership.name", "")
  const { t } = useTranslation()

  if (!isAdmin)
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" mb={2}>
              {t("thankForApprove", { name: fullName })}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {t("approveDetail", { name: fullName })}
            </Typography>
          </Grid>
        </Grid>
      </>
    )

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" mb={2}>
            {t("approveSucceed", { name: fullName })}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {t("confirmApprove", { name: fullName })}
          </Typography>
          <Typography variant="subtitle1">
            {t("userDetail", {
              name: fullName,
              role: roleName,
              dealership: dealershipName,
            })}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default ApprovalSuccess
