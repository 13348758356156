import React from "react"
import { makeStyles } from "@mui/styles"
import {
  FormControl,
  FormLabel,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material"
import NumberFormat from "react-number-format"

const useStyles = makeStyles((theme) => ({
  fieldRoot: {
    marginBottom: theme.spacing(2.5),
    display: (props) => (props.hidden ? "none" : "block"),
  },
  beforeInput: {
    color: "black",
  },
  adornmentPadding: {
    "& .MuiInputAdornment-root": {
      padding: `6px 0 7px`,
    },
  },
}))

const TelephoneFormatCustom = React.forwardRef(function TelephoneFormatCustom(
  props,
  ref
) {
  const { ...other } = props

  return (
    <NumberFormat {...other} getInputRef={ref} format="##########" mark="_" />
  )
})

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      isNumericString
    />
  )
})

const MUIField = React.memo((props) => {
  const {
    type,
    name,
    label,
    placeholder,
    options = [],
    adornment,
    hidden,
    locked,
    beforeInput,
    lockable,
    ...rest
  } = props

  const classes = useStyles({ hidden })
  switch (type) {
    case "select":
      return (
        <div className={classes.fieldRoot}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <TextField
              variant="outlined"
              select
              size="small"
              fullWidth
              InputLabelProps={{
                shrink: false,
              }}
              name={name}
              {...rest}
            >
              {options.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </div>
      )

    case "telephone": {
      return (
        <div className={classes.fieldRoot}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              placeholder={placeholder}
              InputProps={{
                inputComponent: TelephoneFormatCustom,
              }}
              name={name}
              {...rest}
            />
          </FormControl>
        </div>
      )
    }

    case "number": {
      const taxInputProps = { inputComponent: NumberFormatCustom }
      return (
        <div className={classes.fieldRoot}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              placeholder={placeholder}
              InputProps={taxInputProps}
              name={name}
              {...rest}
            />
          </FormControl>
        </div>
      )
    }

    case "text":
    default:
      return (
        <div className={classes.fieldRoot}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{label}</FormLabel>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              type={type}
              placeholder={placeholder}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: adornment?.start ? (
                  <InputAdornment position="start">
                    {adornment.start}
                  </InputAdornment>
                ) : null,
                endAdornment: adornment?.end ? (
                  <InputAdornment position="end">
                    {adornment.end}
                  </InputAdornment>
                ) : null,
              }}
              name={name}
              {...rest}
            />
          </FormControl>
        </div>
      )
  } // end Switch
})

export default MUIField
