import React, { useState, useEffect } from "react"
import { makeStyles } from "@mui/styles"
import { useParams } from "react-router-dom"
import { get } from "lodash"
import { Container, Paper } from "@mui/material"
import { useTranslation } from "react-i18next"

import ApprovalSuccess from "./components/ApprovalSuccess"
import ApprovalError from "./components/ApprovalError"
import ApprovalLoading from "./components/ApprovalLoading"
import RewardHeader from "../../components/RewardHeader"
import { approveUser } from "../../api"
import { parseBase64ToJson } from "../../util/util"

const useStyles = makeStyles((theme) => ({
  approvalScreen: {
    height: "100vh",
    "&.MuiContainer-root ": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  infoBox: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "&.MuiPaper-root": {
      borderRadius: 20,
      boxShadow: "0px 0px 6px 0px rgb(116 115 115 / 44%)",
    },
  },
}))

const Approval = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [userInfo, setUserInfo] = useState({})
  const [tokenInfo, setTokenInfo] = useState({})
  const { token } = useParams()

  useEffect(() => {
    const approve = async (token) => {
      const _approvalInfo = parseBase64ToJson(token)
      if (_approvalInfo) {
        setTokenInfo(_approvalInfo)
        const salesManagerId = get(_approvalInfo, "salesManagerId", null)
        const adminId = get(_approvalInfo, "adminId", null)
        if (salesManagerId || adminId) {
          const { user, error } = await approveUser(!!salesManagerId, token)
          if (user) {
            setLoading(false)
            setUserInfo(user)
          } else setError(error)
        } else setError(t("invalidPayload"))
      } else setError(t("invalidPayload"))
    }

    approve(token)
  }, [token, setTokenInfo, t])

  const isAdmin = !!get(tokenInfo, "adminId", null)

  return (
    <Container maxWidth="sm" className={classes.approvalScreen}>
      <RewardHeader />
      <Paper className={classes.infoBox}>
        {loading && !error && <ApprovalLoading />}
        {error && <ApprovalError message={error} />}
        {!loading && !error && (
          <ApprovalSuccess userInfo={userInfo} isAdmin={isAdmin} />
        )}
      </Paper>
    </Container>
  )
}

export default Approval
