import React from "react"
import { get } from "lodash"
import { Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const RejectionSuccess = ({ userInfo }) => {
  const firstName = get(userInfo, "firstName", "")
  const lastName = get(userInfo, "lastName", "")
  const roleName = get(userInfo, "role.name", "")
  const dealershipName = get(userInfo, "dealership.name", "")
  const fullName = `${firstName} ${lastName}`
  const { t } = useTranslation()

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" mb={2}>
            {t("rejectSucceed", { name: fullName })}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {t("rejectConfirm", { name: fullName })}
          </Typography>
          <Typography variant="subtitle1">
            {t("userDetail", {
              name: fullName,
              role: roleName,
              dealership: dealershipName,
            })}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default RejectionSuccess
