import React, { useEffect } from "react"
import { Typography, Grid, Box, Container } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { get } from "lodash"
import { useTranslation } from "react-i18next"

import LogoSVG from "../../components/logoSvg"
import useAuthProvider from "../../hooks/useAuthProvider"
import { userStatuses } from "../../constants"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100vh",

    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
    },
    "&.MuiContainer-root ": {
      width: "100%",
      padding: 0,
      maxWidth: "100%",
    },
  },
  content: {
    width: "100%",
    height: "100vh",
  },
  headerContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    "& .MuiTypography-root": {
      fontSize: "2rem",
      fontWeight: 900,
      display: "inline",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.5rem",
      },
    },
  },
  blueText: {
    color: theme.palette.primary.main,
  },
  description: {
    fontWeight: 300,
  },
  contentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    justifyContent: "center",
    alignItems: "flex-start",
  },
}))

const RewardsWallet = () => {
  const classes = useStyles()
  const { getRedirectUrl, userInfo } = useAuthProvider()
  const { t } = useTranslation()

  useEffect(() => {
    const isVerified = userInfo?.status === userStatuses.verified

    if (isVerified) {
      const roleId = get(userInfo, "roleId")
      const redirect_url = getRedirectUrl(`${roleId}`)
      window.location = redirect_url
    }
  }, [userInfo, getRedirectUrl])

  return (
    <Container maxWidth="xs">
      <Grid container className={classes.content}>
        <Grid item xs={12}>
          <Box className={classes.contentContainer}>
            <LogoSVG />
            <div className={classes.headerContainer}>
              <Typography variant="h1">My </Typography>
              <Typography variant="h1" className={classes.blueText}>
                BMW
              </Typography>
              <Typography variant="h1">Rewards</Typography>
            </div>
            <Typography variant="span" className={classes.description}>
              {t("waitingForApprove")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default RewardsWallet
