import React, { useState, useEffect } from "react"
import { Typography, Grid, Container } from "@mui/material"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { get } from "lodash"

import useStyles from "./style"
import useDataprovider from "../../hooks/useDataProvider"
import useAuthProvider from "../../hooks/useAuthProvider"
import {
  TotalSales,
  LeaderboardTotalPoints,
  EffectiveRatio,
  UserMenu,
  DealershipField,
} from "./components/Components"
import LeaderboardList from "./components/LeaderboardList"
import Loader from "../../components/Loader"

const Dealership = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { userInfo } = useAuthProvider()
  const currentDealership = get(userInfo, "dealership.id", "")
  const [dealerId, setDealerId] = useState(currentDealership)
  const [leaderboard, setLeaderboard] = useState({
    data: [],
    totalItems: undefined,
  })
  const [totalPoints, setTotalPoints] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [isLoading, setIsLoading] = useState(true)

  const { getLeaderBoardList, getLeaderboardTotalPoints } = useDataprovider()

  useEffect(() => {
    const getData = async () => {
      const skip = pageNumber * itemsPerPage
      const leaderboardQuery = {
        dealershipId: dealerId,
        skip,
        limit: itemsPerPage,
      }
      const totalPointsQuery = { dealershipId: dealerId }
      const leaderboardQueryStr = new URLSearchParams(
        leaderboardQuery
      ).toString()
      const toalPointsQueryStr = new URLSearchParams(
        totalPointsQuery
      ).toString()

      setIsLoading(true)
      const leaderboardList = await getLeaderBoardList(leaderboardQueryStr)
      const leaderboardTotalPoints = await getLeaderboardTotalPoints(
        toalPointsQueryStr
      )

      setLeaderboard(leaderboardList)
      setTotalPoints(leaderboardTotalPoints)
      setIsLoading(false)
    }

    if (dealerId) {
      getData()
    }
  }, [
    pageNumber,
    itemsPerPage,
    getLeaderBoardList,
    getLeaderboardTotalPoints,
    dealerId,
  ])

  return (
    <div className={classes.root}>
      <Loader isEnable={isLoading} disableBackdrop={true} />
      <Container maxWidth="100" className={classes.contentContainer}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6} md={4}>
            <div className={classes.headerContainer}>
              <Typography variant="h5" className={classes.headerText}>
                {t("myDealership")}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={4}>
            <div className={classes.rightContainer}>
              <UserMenu />
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <DealershipField
            related
            handleChange={(dealerId) => {
              setDealerId(dealerId)
              setPageNumber(0)
            }}
          />
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={4} lg={4}>
            <TotalSales totalSales={leaderboard.totalItems} />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <LeaderboardTotalPoints totalPoints={totalPoints} />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <EffectiveRatio />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.headerText}>
                {t("leaderboard")}
              </Typography>
              <Typography variant="subtitle1">
                {`${t("lastUpdate")} ${dayjs().format("MMM D, YYYY")}`}
              </Typography>
            </Grid>
            <LeaderboardList
              leaderboard={leaderboard}
              pageNumber={pageNumber}
              itemsPerPage={itemsPerPage}
              handleChangePageNumber={setPageNumber}
              handleChangeItemsPerPage={setItemsPerPage}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Dealership
