import React from "react"
import Container from "@mui/material/Container"
import Loader from "../../components/Loader"
import NavBar from "../../components/NavBar"

const Loading = () => {
  return (
    <div>
      <NavBar />
      <Container maxWidth="100">
        <div>
          <Loader isEnable={true} disableBackdrop={true} />
        </div>
      </Container>
    </div>
  )
}

export default Loading
