import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  contentContainer: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  headerContainer: {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2.5),
    marginLeft: theme.spacing(2.5),
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  headerText: {
    paddingRight: theme.spacing(1),
    "&.MuiTypography-root": { fontWeight: 900 },
  },
  missingMonthBox: {
    boxShadow: "0px 0px 6px 0px rgb(116 115 115 / 44%)",
    borderRadius: 20,
    padding: 20,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  detailText: {
    color: theme.palette.primary.main,
  },
  emojiIcon: {
    width: 25,
    height: 25,
    marginRight: 5,
    marginBottom: -5,
  },
  selectControl: {
    "&.MuiOutlinedInput-root ": {
      borderRadius: 50,
      fontSize: "0.8rem",
      marginTop: theme.spacing(1),
      lineHeight: 1.7,
      "&.MuiSelect-select": {
        padding: "10px 20px",
      },
    },
  },
  paperContainer: {
    padding: theme.spacing(3),
    "&.MuiPaper-root": {
      borderRadius: 20,
    },
  },
  textFieldContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  gridMarginRight: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
  },
  formContainer: {
    marginBottom: theme.spacing(10),
  },
  rightContainer: {
    textAlign: "right",
  },
}))

export default useStyles
