import React, { useState } from "react"
import useStyles from "./style"
import {
  TotalPayout,
  PreviousPayout,
  NextPayout,
} from "./components/Components"
import {
  FormControl,
  Typography,
  Grid,
  Container,
  Select,
  MenuItem,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ContractList from "./components/ContractList"
import LeaderboardList from "./components/LeaderboardList"
import { UserMenu, DealershipField } from "./components/Components"
import { useTranslation } from "react-i18next"

const OutletDashboard = () => {
  const classes = useStyles()
  const [contractFilter, setContractFilter] = useState("all")
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Container maxWidth="100" className={classes.contentContainer}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6} md={4}>
            <div className={classes.headerContainer}>
              <Typography variant="h5" className={classes.headerText}>
                {t("outletDashboard")}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={4}>
            <div className={classes.rightContainer}>
              <UserMenu />
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <DealershipField />
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} lg={4}>
            <TotalPayout />
          </Grid>
          <Grid item xs={12} lg={4}>
            <PreviousPayout />
          </Grid>
          <Grid item xs={12} lg={4}>
            <NextPayout />
          </Grid>
        </Grid>
        <Grid container className={classes.contentContainer}>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.headerText}>
                {t("leaderboard")}
              </Typography>
              <Typography variant="subtitle1">
                {`${t("lastUpdate")} 30 Oct 2021`}
              </Typography>
            </Grid>
            <LeaderboardList />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.headerText}>
                {t("listOfContracts")}
              </Typography>
              <FormControl>
                <Select
                  IconComponent={ExpandMoreIcon}
                  defaultValue="all"
                  className={classes.selectControl}
                  onChange={(e) => setContractFilter(e.target.value)}
                >
                  <MenuItem value="all">{t("allContracts")}</MenuItem>
                  <MenuItem value="personal">{t("myContracts")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <ContractList contractFilter={contractFilter} />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default OutletDashboard
