import React, { useState, useEffect } from "react"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import thai from "thai-data"
import InputField from "../InputField"

const AddressFields = (props) => {
  const { values, onChange, setFieldValue } = props
  const { t } = useTranslation()
  const { zipcode } = values
  const [subDistrictOptions, setSubDistrictOptions] = useState([])

  useEffect(() => {
    const suggestLocation = (zipcode) => {
      const suggestSubDistrict = thai.autoSuggestion(zipcode).subDistrict
      if (suggestSubDistrict) {
        setSubDistrictOptions(
          suggestSubDistrict.map((option) => ({ value: option, label: option }))
        )
      }
    }

    if (zipcode && zipcode.length === 5) {
      suggestLocation(zipcode)
    }
  }, [zipcode, setFieldValue])

  const handleChangeZipCode = (e) => {
    onChange(e)
    setSubDistrictOptions([])
    setFieldValue("sub_district", "")
    setFieldValue("district", "")
    setFieldValue("province", "")
  }

  const handleSubDistrictChange = (e) => {
    const subDistrict = e.target.value
    setFieldValue("sub_district", subDistrict)
    if (zipcode && subDistrict) {
      const suggestions = thai.autoSuggestion(zipcode, subDistrict)
      setFieldValue("district", suggestions.districtName)
      setFieldValue("province", suggestions.provinceName)
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <InputField
          type={"number"}
          name="zipcode"
          label={t("zipCode")}
          value={values.zipcode}
          onChange={handleChangeZipCode}
          inputProps={{ maxLength: 5 }}
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          type={"select"}
          name="sub_district"
          label={t("subDistrict")}
          value={subDistrictOptions.length > 0 ? values.sub_district : ""}
          onChange={(e) => handleSubDistrictChange(e)}
          options={subDistrictOptions}
          disabled={subDistrictOptions.length === 0}
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          type={"text"}
          name="district"
          label={t("district")}
          value={values.district}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          type={"text"}
          name="province"
          label={t("province")}
          value={values.province}
          disabled
        />
      </Grid>
    </Grid>
  )
}

export default AddressFields
