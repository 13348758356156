import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as Sentry from "@sentry/react"
import "./i18n"

if (process.env.REACT_APP_ENV !== "prod") {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
)
