import React, { useState } from "react"
import {
  Button,
  Typography,
  Grid,
  Box,
  Container,
  AlertTitle,
  Alert,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Formik, Form } from "formik"
import _ from "lodash"
import { useTranslation } from "react-i18next"

import LogoSVG from "../../components/logoSvg"
import InputField from "../../components/InputField"
import useAuthProvider from "../../hooks/useAuthProvider"
import useDataprovider from "../../hooks/useDataProvider"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100vh",

    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
    },
    "&.MuiContainer-root ": {
      width: "100%",
      padding: 0,
      maxWidth: "100%",
    },
  },
  content: {
    width: "100%",
    height: "100vh",
  },
  headerContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    "& .MuiTypography-root": {
      fontSize: "2rem",
      fontWeight: 900,
      display: "inline",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.5rem",
      },
    },
  },
  blueText: {
    color: theme.palette.primary.main,
  },
  description: {
    fontWeight: 300,
  },
  contentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  textFieldContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

const RewardsWallet = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const classes = useStyles()
  const { submitWallet } = useDataprovider()
  const { loadUserInfo } = useAuthProvider()
  const { t } = useTranslation()

  const initialValues = {
    walletNo: "",
  }

  const validate = (values) => {
    const errors = {}

    // Wallet number
    if (!values.walletNo) _.set(errors, "Wallet number", `${t("walletError")}`)
    return errors
  }

  const onSubmit = (values) => {
    setIsSubmitting(true)
    const submitValues = {
      walletNo: values.walletNo,
    }

    submitWallet(submitValues)
      .then((response) => {
        const { success } = response
        if (success) {
          loadUserInfo()
          setIsSubmitting(false)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <Container maxWidth="xs">
      <Grid container className={classes.content}>
        <Grid item xs={12}>
          <Box className={classes.contentContainer}>
            <LogoSVG />
            <div className={classes.headerContainer}>
              <Typography variant="h1">My </Typography>
              <Typography variant="h1" className={classes.blueText}>
                BMW{" "}
              </Typography>
              <Typography variant="h1">Rewards</Typography>
            </div>

            <Typography variant="span" className={classes.description}>
              <img
                src="/images/true-money.png"
                alt="true-money"
                style={{ width: "50px" }}
              />
              {t("wallerDetail")}
            </Typography>
            <Formik
              validate={validate}
              onSubmit={onSubmit}
              initialValues={initialValues}
            >
              {({ errors, isValid, values, handleChange }) => (
                <Form>
                  <div className={classes.textFieldContainer}>
                    <InputField
                      name="walletNo"
                      label={t("trueAccountNumber")}
                      placeholder={t("phoneNumber")}
                      type="telephone"
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    {_.size(errors) > 0 && (
                      <Alert severity="error" style={{ marginBottom: 8 }}>
                        <AlertTitle>Error</AlertTitle>
                        <ul style={{ paddingLeft: 0 }}>
                          {_.map(errors, (error, i) => (
                            <li key={`error-${i}`}>{error}</li>
                          ))}
                        </ul>
                      </Alert>
                    )}
                  </div>
                  <Button
                    variant="contained"
                    disabled={isSubmitting || !isValid}
                    className={classes.primaryBtn}
                    type="submit"
                  >
                    {t("next")}
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export default RewardsWallet
