import React, { useState, useEffect } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
import Grid from "@mui/material/Grid"
import useStyles from "./style"
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ContractList from "./components/ContractList"
import ModalUnstyled from "@mui/base/ModalUnstyled"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import { styled } from "@mui/system"
import {
  TotalContract,
  TotalPoints,
  LeaderboardRank,
  EffectiveRatio,
  UserMenu,
  DealershipField,
} from "./components/Components"
import { useTranslation } from "react-i18next"
import { get, omitBy } from "lodash"

import useAuthProvider from "../../hooks/useAuthProvider"
import useDataProvider from "../../hooks/useDataProvider"
import { getPeriodQueryObject } from "../../util/util"
import Loader from "../../components/Loader"

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`

const style = {
  width: 500,
  bgcolor: "background.paper",
}

const Rewards = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [contractFilter, setContractFilter] = useState("all")
  const [periodFilter, setPeroidFilter] = useState("4")
  const [pageNumber, setPageNumber] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [isLoading, setIsLoading] = useState(true)
  const [contracts, setContracts] = useState({
    data: [],
    totalItems: undefined,
  })
  const { t } = useTranslation()
  const { userInfo } = useAuthProvider()
  const currentDealership = get(userInfo, "dealership.id", null)
  const [dealershipFilter, setDealershipFilter] = useState(currentDealership)
  const { getContractList } = useDataProvider()

  const getContractFilter = ({
    contractFilter,
    dealershipFilter,
    period,
    pageNumber,
    itemsPerPage,
    validationState,
  }) => {
    const skip = pageNumber * itemsPerPage
    const queryObj = {
      ...getPeriodQueryObject(period),
      personal: contractFilter === "personal" ? "true" : "",
      dealershipId: dealershipFilter || "",
      skip,
      limit: itemsPerPage,
      rowPointStatus: validationState,
    }
    const filteredQueryObj = omitBy(queryObj, (value) => value === "")
    return new URLSearchParams(filteredQueryObj).toString()
  }

  useEffect(() => {
    const loadContracts = () => {
      const queryStr = getContractFilter({
        contractFilter,
        dealershipFilter,
        period: periodFilter,
        pageNumber,
        itemsPerPage,
        validationState: "approved",
      })
      setIsLoading(false)
      getContractList(queryStr).then((res) => {
        setContracts({
          data: res?.data,
          totalItems: res?.totalItems,
        })
        setIsLoading(false)
      })
    }

    loadContracts()
  }, [
    contractFilter,
    dealershipFilter,
    getContractList,
    itemsPerPage,
    pageNumber,
    periodFilter,
  ])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleChangeDealership = (dealershipId) => {
    if (!dealershipId) setDealershipFilter("")
    setDealershipFilter(dealershipId)
    setPageNumber(0)
  }

  const handleChangePage = (page) => setPageNumber(page)
  const handleChangeItemsPerPage = (itemsPerPage) =>
    setItemsPerPage(itemsPerPage)

  return (
    <div className={classes.root}>
      <Loader isEnable={isLoading} disableBackdrop={true} />
      <Container maxWidth="100" className={classes.contentContainer}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6} md={4}>
            <div className={classes.headerContainer}>
              <Typography variant="h5" className={classes.headerText}>
                {t("myBmwRewards")}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={4}>
            <div className={classes.rightContainer}>
              <UserMenu />
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <DealershipField
            related
            allowAllDealerships
            handleChange={handleChangeDealership}
          />

          <FormControl>
            <Select
              IconComponent={ExpandMoreIcon}
              defaultValue="4"
              className={classes.selectControl}
              onChange={(e) => setPeroidFilter(e.target.value)}
            >
              <MenuItem value="4">{t("allTime")}</MenuItem>
              <MenuItem value="3">{t("thisYear")}</MenuItem>
              <MenuItem value="2">{t("lastMonth")}</MenuItem>
              <MenuItem value="1">{t("thisMonth")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} md={6} lg={3}>
            <TotalContract totalContracts={contracts?.totalItems} />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TotalPoints
              dealershipId={dealershipFilter}
              period={periodFilter}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <LeaderboardRank
              dealershipId={dealershipFilter}
              period={periodFilter}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <EffectiveRatio
              dealershipId={dealershipFilter}
              period={periodFilter}
            />
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.missingMonthBox}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} md={6}>
                  {/* <Typography variant="span">
                    {t("missionOfTheMonth")} */}
                  <Typography variant="h5">{t("welcome")}</Typography>
                  <Typography variant="h6">
                    {/* <strong>X3 Points</strong> for freedom choice contract */}
                    {t("youreInvited")
                      .split("\n")
                      .map((line) => (
                        <span key={`invited-${line}`}>
                          {line}
                          <br />
                        </span>
                      ))}
                  </Typography>
                  <Typography variant="span" className={classes.detailText}>
                    {/* {t("getPointAndBadge")} */}
                    {t("helpImprove")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img
                    src="images/BMW/missing-month-model.png"
                    alt="BMW"
                    style={{ width: "70%" }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Button variant="outlined" onClick={handleOpen}>
                    {t("details")}
                  </Button>
                  <StyledModal
                    aria-labelledby="unstyled-modal-title"
                    aria-describedby="unstyled-modal-description"
                    open={open}
                    onClose={handleClose}
                    BackdropComponent={Backdrop}
                  >
                    <Box sx={style}>
                      <Box
                        sx={{
                          backgroundColor: "#2D2E2F",
                          p: 2,
                          textAlign: "center",
                          color: "#fff",
                        }}
                      >
                        <Box display="flex" justifyContent="flex-end">
                          <CloseRoundedIcon onClick={handleClose} />
                        </Box>
                        <Typography variant="h6">{t("betaThanks")}</Typography>
                        {/* <Typography variant="span">
                          <strong>X3 Points</strong> for freedom choice contract
                        </Typography> */}
                      </Box>
                      <Box sx={{ p: 4 }}>
                        <Typography variant="body1" textAlign="center">
                          {t("betaDetailModal")
                            .split("\n")
                            .map((line) => (
                              <p key={`beta-detail-${line}`}>{line}</p>
                            ))}
                          <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSfqX6OdDrwEhKJJi_4J5NXDTLsGiHtUO9DgIgnFAWUSQx-HUw/viewform?usp=sf_link"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Typography
                              variant="span"
                              className={classes.detailText}
                            >
                              {t("here")}
                            </Typography>
                          </a>
                        </Typography>
                      </Box>
                    </Box>
                  </StyledModal>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.headerText}>
                {t("listOfContracts")}
              </Typography>
              <FormControl>
                <Select
                  IconComponent={ExpandMoreIcon}
                  defaultValue="all"
                  className={classes.selectControl}
                  onChange={(e) => setContractFilter(e.target.value)}
                >
                  <MenuItem value="all">{t("allContracts")}</MenuItem>
                  <MenuItem value="personal">{t("myContracts")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <ContractList
              contractFilter={contractFilter}
              dealershipFilter={dealershipFilter}
              period={periodFilter}
              contracts={contracts}
              pageNumber={pageNumber}
              itemsPerPage={itemsPerPage}
              handleChangePage={handleChangePage}
              handleChangeItemsPerPage={handleChangeItemsPerPage}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Rewards
