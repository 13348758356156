import React from "react"
import _ from "lodash"
import LogoSVG from "../logoSvg"
import { NavLink } from "react-router-dom"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import { makeStyles } from "@mui/styles"
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined"
import CloseIcon from "@mui/icons-material/Close"
import AdjustIcon from "@mui/icons-material/Adjust"
import useAuthProvider from "../../hooks/useAuthProvider"
import { useTranslation } from "react-i18next"
import Button from "@mui/material/Button"

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  appBar: {
    width: { sm: `calc(100% - ${drawerWidth}px)` },
    ml: { sm: `${drawerWidth}px` },
    backgroundColor: "transparent !important",
    display: "none !important",
    boxShadow: "none !important",
    minHeight: 30,
    [theme.breakpoints.down("md")]: {
      display: "flex !important",
      backgroundColor: "#2D2E2F !important",
    },
  },
  isActive: {
    backgroundColor: "#e0f5fd",
    color: "#0080ff",
  },
  containerLogo: {
    width: "200px",
    marginLeft: "20px",
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
    },
  },
  menuItem: {
    textTransform: "uppercase",

    "& .MuiListItemText-root": {
      color: "#b1b1b1",
      letterSpacing: 1,
      "& .MuiTypography-root": {
        fontSize: "0.8em",
      },
    },
    "& .MuiListItemIcon-root": {
      color: "#b1b1b1",
      minWidth: "30px",
      "& .MuiSvgIcon-root": {
        fontSize: "0.8em",
      },
    },
    "&.active": {
      "& .MuiListItemText-root,& .MuiListItemIcon-root": {
        color: "#fff",
      },
    },
  },
  closeIcon: {
    color: "#fff",
    cursor: "pointer",
    marginBottom: theme.spacing(3),
  },
}))

export default function NavBar(props) {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const classes = useStyles()
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const { userInfo } = useAuthProvider()

  const roleId = userInfo?.role?.id
  const { t } = useTranslation()
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const listMenus = _.compact([
    _.includes([2, 3, 4], roleId) && {
      name: `${t("myRewards")}`,
      href: "/",
    },
    _.includes([2, 3, 4], roleId) && {
      name: `${t("myPayment")}`,
      href: "/payment",
    },
    _.includes([2, 3], roleId) && {
      name: `${t("myDealership")}`,
      href: "/dealership",
    },
    _.includes([5, 6], roleId) && {
      name: `${t("outletDashboard")}`,
      href: "/outlet",
    },
    _.includes([2, 3, 4, 5, 6], roleId) && {
      name: `${t("myAccount")}`,
      href: "/account",
    },
  ])

  const manualPath = _.includes([2, 3], roleId) ? "SM" : "SC"

  const drawer = (
    <div>
      <div className={classes.containerLogo}>
        <Box display="flex" justifyContent="flex-end">
          <CloseIcon
            onClick={handleDrawerToggle}
            className={classes.closeIcon}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          />
        </Box>
        <LogoSVG theme={"light"} size={"30%"} />
      </div>
      <List>
        {listMenus.map((menu, index) => (
          <ListItem
            button
            component={NavLink}
            to={menu.href}
            key={`menu-${index}`}
            className={classes.menuItem}
            selected={selectedIndex === index}
            onClick={(event) => handleListItemClick(event, index)}
          >
            <ListItemIcon>
              {selectedIndex === index ? (
                <AdjustIcon />
              ) : (
                <CircleOutlinedIcon />
              )}
            </ListItemIcon>

            <ListItemText primary={menu.name} />
          </ListItem>
        ))}
      </List>
      {/* <ListItem
        button
        key={`menu-help`}
        component={NavLink}
        to="/help"
        className={classes.menuItem}
        style={{ position: "absolute", bottom: "2%" }}
      >
        <ListItemIcon>
          <CircleOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary={t("helpCenter")} />
      </ListItem> */}
      <Button
        key={`menu-help`}
        className={classes.menuItem}
        style={{
          position: "absolute",
          bottom: "2%",
          padding: "8px 16px",
          fontSize: "0.8rem",
          color: "rgb(177,177,177)",
        }}
        href={`/manual/${manualPath}_Manual.pdf`}
        download
      >
        <CircleOutlinedIcon
          style={{
            marginRight: 18,
            fontSize: "0.8rem",
          }}
        />
        {t("downloadManual")}
      </Button>
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { md: "none" },
              position: "absolute",
              right: 0,
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#2D2E2F",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#2D2E2F",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  )
}
