import React, { useEffect } from "react"
import InputField from "../../../components/InputField"
import { Field, useFormikContext } from "formik"
import _ from "lodash"
import useDataprovider from "../../../hooks/useDataProvider"
import { ROLE, ROLENAME } from "../../../constants"

/*
 * Default FormField, for normal Input
 **/
class FormField extends React.PureComponent {
  static defaultProps = {
    inputRowStyle: { position: "relative" },
  }

  render() {
    const {
      name,
      type,
      label,
      inputRowStyle,
      inputFieldProps,
      hidden,
      ...rest
    } = this.props
    return (
      <Field name={name}>
        {({ field }) => {
          return (
            <div>
              <div style={inputRowStyle}>
                <InputField
                  type={type}
                  label={label}
                  hidden={hidden}
                  {...field}
                  {...inputFieldProps}
                  {...rest}
                />
              </div>
            </div>
          )
        }}
      </Field>
    )
  }
}

export const RoleField = (props) => {
  const options = [
    { value: ROLE.SENIOR_SALES_MANAGER, label: ROLENAME.SENIOR_SALES_MANAGER },
    { value: ROLE.SALES_MANAGER, label: ROLENAME.SALES_MANAGER },
    { value: ROLE.SALES_CONSULTANT, label: ROLENAME.SALES_CONSULTANT },
  ]

  return (
    <FormField
      name={props.name}
      type="select"
      label={props.label}
      options={options}
    />
  )
}

export const DealershipField = (props) => {
  const { setFieldValue } = useFormikContext()
  const { getAllDealerships } = useDataprovider()
  const [dealerships, setDealerships] = React.useState([])

  const options = _.map(dealerships, (value) => {
    return {
      value: value.id,
      label: value.name,
    }
  })

  useEffect(() => {
    if (dealerships.length === 0) {
      getAllDealerships().then(setDealerships)
      setFieldValue("sales_manager", "")
    }
  }, [getAllDealerships, setFieldValue, dealerships])

  return (
    <FormField
      name={props.name}
      type="select"
      label={props.label}
      options={options}
    />
  )
}

export const SalesManagerField = (props) => {
  const { dealershipId } = props
  const { getSalesManager } = useDataprovider()
  const [salesManager, setSalesManager] = React.useState([])

  const options = _.uniqWith(
    _.map(salesManager, (value) => {
      return {
        value: value.id,
        label: `${value.firstName} ${value.lastName}`,
      }
    }),
    _.isEqual
  )

  useEffect(() => {
    if (dealershipId) getSalesManager(dealershipId).then(setSalesManager)
  }, [getSalesManager, dealershipId])

  return (
    <FormField
      name={props.name}
      type="select"
      label={props.label}
      options={options}
    />
  )
}
