import React, { Fragment, Suspense } from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom"
import useAuthProvider, { AuthProvider } from "./hooks/useAuthProvider"
import { DataProvider } from "./hooks/useDataProvider"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import "./style/globals.css"
import { makeStyles } from "@mui/styles"
import { useTheme } from "@mui/material/styles"
import theme from "./style/theme"
import Container from "@mui/material/Container"
import ReactGA from "react-ga"

import Login from "./pages/login"
import Rewards from "./pages/Rewards/rewards"
import Payment from "./pages/Rewards/payment"
import Account from "./pages/Rewards/account"
import NavBar from "./components/NavBar/NavBar"
import Wallet from "./pages/Register/rewardsWallet"
import Billing from "./pages/Register/billing"
import Information from "./pages/Register/information"
import PendingApproval from "./pages/Register/pendingApproval"
import Dealership from "./pages/Rewards/dealership"
import OutletDashboard from "./pages/Rewards/outletDashboard"
import Approval from "./pages/Register/approval"
import Rejection from "./pages/Register/rejection"
import NotFound from "./pages/Error/notFound"
import AccessDenied from "./pages/Error/accessDenied"
import Loading from "./pages/Loading"
import { ROLE, userStatuses, signupStep } from "./constants"
import config from "./config"

if (config.environment === "prod") {
  const trackingId = config.trackingId
  ReactGA.initialize(trackingId)
}

const PrivateRoute = ({ children, roles }) => {
  const { isLogin, code, user, userInfo } = useAuthProvider()
  let location = useLocation()

  if (code) return <Loading />
  if (!code && !user && !userInfo && location.pathname !== "/login") {
    return <Navigate to="/login" />
  }

  if (!code && user) {
    const isNewUser = isNaN(user.user_id)

    if (!isNewUser && !userInfo) return <Loading />
    if (isNewUser && !userInfo && location.pathname !== "/information")
      return <Navigate to="/information" />

    if (userInfo) {
      if (
        userInfo.signupStep === signupStep.wallet &&
        location.pathname !== "/wallet"
      )
        return <Navigate to="/wallet" />
      if (
        userInfo.signupStep === signupStep.billing &&
        location.pathname !== "/billing"
      )
        return <Navigate to="/billing" />
      if (
        userInfo.status === userStatuses.unverified &&
        location.pathname !== "/approval"
      )
        return <Navigate to="/approval" />

      const roleId = isNewUser ? "0" : `${userInfo.roleId}`
      const userHasRequiredRole =
        roleId && roles.includes(roleId) ? true : false

      if (isLogin && !userHasRequiredRole) return <AccessDenied />
    }
  }

  return children
}

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiContainer-root ": {
      width: "100%",
      padding: 0,
      maxWidth: "100%",
    },
  },
  content: {
    paddingLeft: "264px !important",
    [useTheme().breakpoints.down("md")]: {
      paddingLeft: "24px !important",
    },
  },
}))

function App() {
  const classes = useStyles()

  return (
    <Suspense fallback="loading">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <DataProvider>
            <Container className={classes.root}>
              <Router>
                <Fragment>
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/wallet"
                      element={
                        <PrivateRoute
                          roles={[
                            ROLE.NEW_USER,
                            ROLE.SALES_CONSULTANT,
                            ROLE.SALES_MANAGER,
                            ROLE.SENIOR_SALES_MANAGER,
                          ]}
                        >
                          <Wallet />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/billing"
                      element={
                        <PrivateRoute
                          roles={[
                            ROLE.NEW_USER,
                            ROLE.SALES_CONSULTANT,
                            ROLE.SALES_MANAGER,
                            ROLE.SENIOR_SALES_MANAGER,
                          ]}
                        >
                          <Billing />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/information"
                      element={
                        <PrivateRoute roles={[ROLE.NEW_USER]}>
                          <Information />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/approval"
                      element={
                        <PrivateRoute
                          roles={[
                            ROLE.NEW_USER,
                            ROLE.SALES_CONSULTANT,
                            ROLE.SALES_MANAGER,
                            ROLE.SENIOR_SALES_MANAGER,
                          ]}
                        >
                          <PendingApproval />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/"
                      element={
                        <PrivateRoute
                          roles={[
                            ROLE.SALES_CONSULTANT,
                            ROLE.SALES_MANAGER,
                            ROLE.SENIOR_SALES_MANAGER,
                          ]}
                        >
                          <NavBar />
                          <Container maxWidth="100" className={classes.content}>
                            <Rewards />
                          </Container>
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route
                      path="/payment"
                      element={
                        <PrivateRoute
                          roles={[
                            ROLE.SALES_CONSULTANT,
                            ROLE.SALES_MANAGER,
                            ROLE.SENIOR_SALES_MANAGER,
                          ]}
                        >
                          <NavBar />
                          <Container maxWidth="100" className={classes.content}>
                            <Payment />
                          </Container>
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route
                      path="/account"
                      element={
                        <PrivateRoute
                          roles={[
                            ROLE.SALES_CONSULTANT,
                            ROLE.SALES_MANAGER,
                            ROLE.SENIOR_SALES_MANAGER,
                            ROLE.GENERAL_MANAGER,
                            ROLE.OWNER,
                          ]}
                        >
                          <NavBar />
                          <Container maxWidth="100" className={classes.content}>
                            <Account />
                          </Container>
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route
                      path="/dealership"
                      element={
                        <PrivateRoute
                          roles={[
                            ROLE.SALES_MANAGER,
                            ROLE.SENIOR_SALES_MANAGER,
                          ]}
                        >
                          <NavBar />
                          <Container maxWidth="100" className={classes.content}>
                            <Dealership />
                          </Container>
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route
                      path="/outlet"
                      element={
                        <PrivateRoute
                          roles={[ROLE.GENERAL_MANAGER, ROLE.OWNER]}
                        >
                          <NavBar />
                          <Container maxWidth="100" className={classes.content}>
                            <OutletDashboard />
                          </Container>
                        </PrivateRoute>
                      }
                    ></Route>
                    <Route path="/approve/:token" element={<Approval />} />
                    <Route path="/reject/:token" element={<Rejection />} />
                    <Route path="*" element={<NotFound />}></Route>
                  </Routes>
                </Fragment>
              </Router>
            </Container>
          </DataProvider>
        </AuthProvider>
      </ThemeProvider>
    </Suspense>
  )
}

export default App
