import React, { useEffect, useState } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import useStyles from "./style"
import Container from "@mui/material/Container"
import PaymentList from "./components/PaymentList"
import { useTranslation } from "react-i18next"
import useDataprovider from "../../hooks/useDataProvider"
import Loader from "../../components/Loader"
import {
  TotalPayout,
  PreviousPayout,
  NextPayout,
  UserMenu,
} from "./components/Components"

const Payment = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    getTotalPayout,
    getPreviousPayout,
    getTransactionList,
    getNextPayout,
  } = useDataprovider()

  const [isPayoutLoading, setPayoutLoading] = useState(false)
  const [isListLoading, setListLoading] = useState(false)
  const [totalPayout, setTotalPayout] = useState(0)
  const [prevPayout, setPrevPayout] = useState({
    paidAmount: 0,
    paidDate: undefined,
  })
  const [nextPayout, setNextPayout] = useState(0)
  const [pageParams, setPageParams] = useState({
    pageNo: 0,
    limit: 10,
  })
  const [payments, setPayments] = useState({
    data: [],
    totalItems: undefined,
  })

  useEffect(() => {
    const fetchPayouts = async () => {
      setPayoutLoading(true)
      const amount = await getTotalPayout()
      const prevPayout = await getPreviousPayout()
      const nextPayout = await getNextPayout()
      setTotalPayout(amount)
      setPrevPayout({
        paidAmount: prevPayout?.totalAmount,
        paidDate: prevPayout?.createdAt,
      })
      setNextPayout(nextPayout?.totalAmount)
      setPayoutLoading(false)
    }
    fetchPayouts()
  }, [getTotalPayout, getPreviousPayout, getNextPayout])

  useEffect(() => {
    const { pageNo, limit } = pageParams
    const skip = pageNo * limit
    const queryStr = new URLSearchParams({ skip, limit }).toString()
    const fetchPayments = async () => {
      setListLoading(true)
      const transactions = await getTransactionList(queryStr)
      setPayments({
        data: transactions?.data,
        totalItems: transactions?.totalItems,
      })
      setListLoading(false)
    }
    fetchPayments()
  }, [pageParams, getTransactionList])

  return (
    <div className={classes.root}>
      <Loader
        isEnable={isPayoutLoading || isListLoading}
        disableBackdrop={true}
      />
      <Container maxWidth="100" className={classes.contentContainer}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6} md={4}>
            <div className={classes.headerContainer}>
              <Typography variant="h5" className={classes.headerText}>
                {t("myPayment")}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={4}>
            <div className={classes.rightContainer}>
              <UserMenu />
            </div>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} lg={4}>
            <TotalPayout totalPayout={totalPayout} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <PreviousPayout prevPayout={prevPayout} />
          </Grid>
          <Grid item xs={12} lg={4}>
            <NextPayout nextPayout={nextPayout} />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Box>
              <Typography variant="h6" className={classes.headerText}>
                {t("payments")}
              </Typography>
              <PaymentList
                payments={payments}
                pageParams={pageParams}
                setPageParams={setPageParams}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Payment
