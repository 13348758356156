import { Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"

import LogoSVG from "../logoSvg"

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    "& .MuiTypography-root": {
      fontSize: "2rem",
      fontWeight: 900,
      display: "inline",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.5rem",
      },
    },
  },
  blueText: {
    color: theme.palette.primary.main,
  },
}))

const RewardHeader = () => {
  const classes = useStyles()
  return (
    <>
      <LogoSVG />
      <div className={classes.headerContainer}>
        <Typography variant="h1">My </Typography>
        <Typography variant="h1" className={classes.blueText}>
          BMW{" "}
        </Typography>
        <Typography variant="h1">Rewards</Typography>
      </div>
    </>
  )
}

export default RewardHeader
