import React, { useState } from "react"
import {
  Button,
  Typography,
  Grid,
  Container,
  Alert,
  AlertTitle,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import LogoSVG from "../../components/logoSvg"
import InputField from "../../components/InputField"
import { Formik, Form } from "formik"
import {
  RoleField,
  DealershipField,
  SalesManagerField,
} from "./components/Fields"
import _ from "lodash"
import useAuthProvider from "../../hooks/useAuthProvider"
import useDataprovider from "../../hooks/useDataProvider"
import { ROLE } from "../../constants"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100vh",

    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
    },
    "&.MuiContainer-root ": {
      width: "100%",
      padding: 0,
      maxWidth: "100%",
    },
  },
  content: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  headerContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    "& .MuiTypography-root": {
      fontSize: "2rem",
      fontWeight: 900,
      display: "inline",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.5rem",
      },
    },
  },
  blueText: {
    color: theme.palette.primary.main,
  },
  description: {
    fontWeight: 300,
  },
  formContainer: {
    marginTop: theme.spacing(3),
  },
  gridMarginRight: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
  },
}))

const Information = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const classes = useStyles()
  const { user, updateTokenByRefreshToken, loadUserInfo } = useAuthProvider()
  const { submitNewUser } = useDataprovider()
  const { t } = useTranslation()

  const initialValues = {
    name_thai: "",
    name_eng: "",
    email: "",
    phone: "",
    role: "",
    dealership: "",
    sales_manager: "",
  }

  const validate = (values) => {
    const errors = {}
    if (!values.name_thai) _.set(errors, "name_thai", `${t("thaiNameError")}`)
    if (!values.name_eng) _.set(errors, "name_eng", `${t("firstNameError")}`)
    if (!values.lastname_eng)
      _.set(errors, "lastname_eng", `${t("lastNameError")}`)
    if (!values.phone) _.set(errors, "phone", `${t("phoneError")}`)
    if (!values.role) _.set(errors, "role", `${t("roleError")}`)
    if (!values.dealership) _.set(errors, "dealership", `${t("dealerError")}`)
    if (values.role === ROLE.SALES_CONSULTANT && !values.sales_manager)
      _.set(errors, "sales_manager", `${t("managerError")}`)

    return errors
  }

  const onSubmit = async (values) => {
    setIsSubmitting(true)
    const formattedSalesManagerId =
      values.role === ROLE.SALES_CONSULTANT ? values.sales_manager : null
    const submitValues = {
      roleId: values.role,
      dealershipId: `${values.dealership}`,
      username: user.username,
      email: user.email,
      firstName: values.name_eng,
      lastName: values.lastname_eng,
      thaiName: values.name_thai,
      telephone: values.phone,
      salesManagerId: formattedSalesManagerId,
      // ! DEVELOPER NOTE ! //
      // TO UPDATE DEFAULT salesManagerId
      // When users are SeniorSalesManager role
    }

    submitNewUser(submitValues)
      .then((data) => {
        if (!data.error) {
          updateTokenByRefreshToken()
          loadUserInfo()
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <Container maxWidth="xs">
      <Grid container className={classes.content}>
        <Grid item xs={12}>
          <LogoSVG />
          <div className={classes.headerContainer}>
            <Typography variant="h1">My </Typography>
            <Typography variant="h1" className={classes.blueText}>
              BMW{" "}
            </Typography>
            <Typography variant="h1">Rewards</Typography>
          </div>

          <Typography variant="span" className={classes.description}>
            {t("completeInfo")}
          </Typography>
          <Formik
            validate={validate}
            onSubmit={onSubmit}
            initialValues={initialValues}
          >
            {({ errors, isValid, values, handleChange }) => (
              <Form>
                <Grid container className={classes.formContainer}>
                  <Grid item xs={12}>
                    <InputField
                      name="name_thai"
                      label={t("nameInThai")}
                      placeholder={t("nameInThai")}
                      type="text"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <div className={classes.gridMarginRight}>
                      <InputField
                        name="name_eng"
                        label={t("firstName")}
                        placeholder={t("firstName")}
                        type="text"
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputField
                      name="lastname_eng"
                      label={t("lastName")}
                      placeholder={t("lastName")}
                      type="text"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputField
                      name="email"
                      label={t("email")}
                      value={user.email}
                      InputProps={{
                        readOnly: true,
                      }}
                      type="text"
                      onChange={handleChange}
                    />
                    <InputField
                      name="phone"
                      label={t("phoneNumber")}
                      placeholder={t("phoneNumber")}
                      type="telephone"
                      onChange={handleChange}
                    />
                    <RoleField name="role" label={t("role")} />
                    <DealershipField
                      name="dealership"
                      label={t("dealership")}
                    />
                    {values.role === ROLE.SALES_CONSULTANT && (
                      <SalesManagerField
                        name="sales_manager"
                        label={t("salesManager")}
                        dealershipId={values.dealership}
                      />
                    )}
                    <div>
                      {_.size(errors) > 0 && (
                        <Alert severity="error" style={{ marginBottom: 8 }}>
                          <AlertTitle>Error</AlertTitle>
                          <ul style={{ paddingLeft: 0 }}>
                            {_.map(errors, (error, i) => (
                              <li key={`error-${i}`}>{error}</li>
                            ))}
                          </ul>
                        </Alert>
                      )}
                    </div>
                  </Grid>
                  <Button
                    variant="contained"
                    disabled={isSubmitting || !isValid}
                    className={classes.primaryBtn}
                    type="submit"
                  >
                    {t("next")}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Information
