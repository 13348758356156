import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Typography, Box, Container } from "@mui/material"
import { makeStyles } from "@mui/styles"
import useAuthProvider from "../../hooks/useAuthProvider"
import { useTranslation } from "react-i18next"

import LogoSVG from "../../components/logoSvg"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
  },
  contentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  labelText: {
    "&.MuiTypography-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  btnContainer: {
    "& button:nth-child(2)": {
      marginLeft: theme.spacing(1),
    },
  },
}))

const AccessDenied = () => {
  const classes = useStyles()
  const { logout } = useAuthProvider()
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Container maxWidth="xs" className={classes.root}>
      <Box className={classes.contentContainer}>
        <LogoSVG />
        <Typography variant="span" className={classes.labelText}>
          {t("notAuth")}
        </Typography>
        <Box className={classes.btnContainer}>
          <Button variant="contained" onClick={() => navigate(-1)}>
            {t("backHome")}
          </Button>
          <Button variant="contained" onClick={() => logout()}>
            {t("logout")}
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default AccessDenied
