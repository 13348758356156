import React, { useState } from "react"
import dayjs from "dayjs"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableFooter from "@mui/material/TableFooter"
import TablePagination from "@mui/material/TablePagination"
import TablePaginationActions from "../../../components/Pagination/TablePageActions"
import Collapse from "@mui/material/Collapse"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import IconButton from "@mui/material/IconButton"
import AssociatedContractsList from "./AssociatedContractsList"
import NumberFormat from "react-number-format"
import { makeStyles } from "@mui/styles"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 4px",
    "& .MuiTableHead-root": {
      borderBottom: "1px solid #757575",
    },
  },
  thead: {
    "& th": {
      color: "#757575",
    },
  },
  contractTable: {
    "& .MuiTable-root": {
      borderBottom: "none",
    },
    "& tr": {
      "& .MuiTableCell-root": {
        border: "none",
      },
    },
  },
  emojiIcon: {
    width: 25,
    height: 25,
    marginLeft: 5,
    marginBottom: -5,
    color: "#757575",
  },
}))

const Row = (props) => {
  const { row } = props
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {dayjs(row?.createdAt).format("MMMM YYYY")}
        </TableCell>
        <TableCell>{row?.totalContracts}</TableCell>
        <TableCell>
          <NumberFormat
            value={row?.totalPoints}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={0}
          />
        </TableCell>
        <TableCell>
          <NumberFormat
            value={row?.totalAmount}
            displayType={"text"}
            suffix={" THB"}
            thousandSeparator={true}
            decimalScale={0}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          className={classes.contractTable}
          style={{ padding: 0 }}
          colSpan={7}
        >
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <AssociatedContractsList paymentDate={row?.createdAt} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const PaymentList = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { payments, pageParams, setPageParams } = props

  const PaymentTable = () => {
    return (
      <TableContainer>
        <Table className={classes.table} aria-label="collapsible table">
          <TableHead className={classes.thead}>
            <TableRow>
              <TableCell>{t("rewardPeriod")}</TableCell>
              <TableCell>{t("totalContract")}</TableCell>
              <TableCell>{t("totalPoint")}</TableCell>
              <TableCell>{t("totalRewards")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tbody}>
            {payments?.data?.map((payment, index) => (
              <Row key={index} row={payment} />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={8}
                page={pageParams?.pageNo}
                count={payments?.totalItems}
                rowsPerPageOptions={[10, 25, 50]}
                rowsPerPage={pageParams?.limit}
                onPageChange={(event, newPage) => {
                  setPageParams({ ...pageParams, pageNo: newPage })
                }}
                onRowsPerPageChange={(event) => {
                  const limit = event.target.value
                  setPageParams({ pageNo: 0, limit })
                }}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    )
  }

  const DataNotFound = () => {
    return (
      <Box sx={{ width: "100%", textAlign: "center" }}>
        <Typography variant="span" className={classes.headerText}>
          {t("noDataAvail")}
        </Typography>
      </Box>
    )
  }

  return payments?.data?.length > 0 ? <PaymentTable /> : <DataNotFound />
}

export default PaymentList
