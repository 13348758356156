import axios from "axios"

export const approveUser = (isSaleManager, approvalToken) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

  const salesManagerApprovalEndpoint = `${API_ENDPOINT}/verifications/salesManager`
  const adminApprovalEndpoint = `${API_ENDPOINT}/verifications/admin`
  const apiEndpoint = isSaleManager
    ? salesManagerApprovalEndpoint
    : adminApprovalEndpoint

  return axios
    .post(apiEndpoint, { token: approvalToken })
    .then((res) => res?.data?.data)
    .catch((error) => ({ error: error.message }))
}
