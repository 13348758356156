import dayjs from "dayjs"
import { reduce } from "lodash"

export const getPeriodQueryObject = (period) => {
  let from = ""
  let to = ""

  switch (period) {
    case "1": //This Month
      from = dayjs().startOf("month").format("YYYY-MM-DD") //dayjs().startOf("month").date()
      to = dayjs().endOf("month").format("YYYY-MM-DD")
      break
    case "2": //Last Month
      from = dayjs().startOf("month").subtract(1, "month").format("YYYY-MM-DD")
      to = dayjs().endOf("month").subtract(1, "month").format("YYYY-MM-DD")
      break
    case "3": //This Year
      from = dayjs().startOf("year").format("YYYY-MM-DD")
      to = dayjs().endOf("year").format("YYYY-MM-DD")
      break
    default:
      from = ""
      to = ""
  }

  if (!from && !to) return {}
  return { from, to }
}

export function getPeriodQuery(period) {
  const { from, to } = getPeriodQueryObject(period)

  return from && to ? `from=${from}&to=${to}` : ""
}

export const parseBase64ToJson = (base64String) => {
  try {
    const decodedBase64 = Buffer(base64String, "base64").toString()
    const jsonResponse = JSON.parse(decodedBase64)
    return jsonResponse
  } catch {
    return null
  }
}

export const convertObjectToQueryString = (data) => {
  const queryString = reduce(
    data,
    (acc, value, key) => {
      const newQueryString = `${key}=${value}`
      if (value && !acc) return newQueryString
      else if (value) return `${acc}&${newQueryString}`
      return acc
    },
    ""
  )

  return queryString
}
