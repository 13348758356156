import React, { useState } from "react"
import { set, map, size, includes } from "lodash"
import {
  Container,
  Grid,
  Typography,
  Chip,
  Button,
  Paper,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  styled,
  AlertTitle,
  Alert,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import thai from "thai-data"

import AddressFields from "../../components/AddressFields"
import InputField from "../../components/InputField"
import useDataprovider from "../../hooks/useDataProvider"
import useAuthProvider from "../../hooks/useAuthProvider"
import { UserMenu } from "./components/Components"
import ChangePasswordForm from "./components/ChangePasswordForm"
import useStyles from "./style"

const EditAccountDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    justifyContent: " flex-start",
  },
}))

const EditAccountDialogTitle = (props) => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.secondary.main,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

const Account = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    useState(false)
  const { updateProfile } = useDataprovider()
  const { userInfo, loadUserInfo } = useAuthProvider()
  const { t } = useTranslation()
  const locations = thai.allData()
  const allowedZipcode = locations.map((location) => location.zipCode)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handlePasswordDialogOpen = () => {
    setOpenChangePasswordDialog(true)
  }
  const handlePasswordDialogClose = () => {
    setOpenChangePasswordDialog(false)
  }

  const roleName = userInfo?.role.name

  const handleAddress = (_address, n) => {
    if (_address) {
      const addresArray = _address.split(",")
      return addresArray[n]
    }
    return ""
  }

  const initialValues = {
    thaiName: userInfo?.thaiName || "",
    firstName: userInfo?.firstName || "",
    lastName: userInfo?.lastName || "",
    email: userInfo?.email || "",
    telephone: userInfo?.telephone || "",
    nationalId: userInfo?.nationalId || "",
    address: handleAddress(userInfo?.address, 0) || "",
    province: handleAddress(userInfo?.address, 1) || "",
    district: handleAddress(userInfo?.address, 2) || "",
    sub_district: handleAddress(userInfo?.address, 3) || "",
    zipcode: handleAddress(userInfo?.address, 4) || "",
    tmnNumber: userInfo?.tmnNumber || "",
  }

  const validate = (values) => {
    const errors = {}
    if (!values.thaiName) set(errors, "name", `${t("nameError")}`)
    if (!values.firstName) set(errors, "firstName", `${t("firstNameError")}`)
    if (!values.lastName) set(errors, "lastName", `${t("lastNameError")}`)
    if (!values.address) set(errors, "address", `${t("addressError")}`)
    if (!values.tmnNumber) set(errors, "tmnNumber", `${t("accountError")}`)
    if (!values.zipcode) set(errors, "zipcode", `${t("zipCodeError")}`)
    if (!includes(allowedZipcode, values.zipcode))
      set(errors, "zipcode", `${t("invalidZipCode")}`)
    if (!values.nationalId || values.nationalId.length !== 13)
      set(errors, "nationalId", `${t("nationalIdError")}`)

    return errors
  }

  const onSubmit = async (values, { setErrors }) => {
    const combinedValues = combineAddressValues(values)
    const submitValues = getModifiedValues(combinedValues, initialValues)

    updateProfile(submitValues)
      .then(({ success, errorField }) => {
        if (success) {
          loadUserInfo()
          handleClose()
        } else if (!success && errorField === "nationalId") {
          setErrors({ nationalId: t("nationalIdDuplicated") })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const combineAddressValues = (values) => {
    const { address, province, district, sub_district, zipcode } = values
    const result = [address, province, district, sub_district, zipcode].join(
      ","
    )

    const output = {
      thaiName: values.thaiName,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      telephone: values.telephone,
      tmnNumber: values.tmnNumber,
      nationalId: values.nationalId,
      address: result,
    }
    return output
  }

  const getModifiedValues = (values, initialValues) => {
    let modifiedValues = {}

    if (values) {
      Object.entries(values).forEach((entry) => {
        let key = entry[0]
        let value = entry[1]
        if (value !== initialValues[key]) {
          modifiedValues[key] = value
        }
      })
    }

    return modifiedValues
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="100" className={classes.contentContainer}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6} md={4}>
            <div className={classes.headerContainer}>
              <Typography variant="h5" className={classes.headerText}>
                {t("myAccount")}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={6} md={4}>
            <div className={classes.rightContainer}>
              <UserMenu />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.headerContainer}>
              <Typography variant="h6">
                {userInfo?.firstName} {userInfo?.lastName}
              </Typography>
              <Chip label={roleName} />
            </div>
            <Paper elevation={5} className={classes.paperContainer}>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                  <Typography variant="span" className={classes.headerText}>
                    {t("info")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant="span">{t("thaiName")}</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography variant="span">{userInfo?.thaiName}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant="span">{t("englishName")}</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography variant="span">
                    {userInfo?.firstName} {userInfo?.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant="span">{t("email")}</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography variant="span">{userInfo?.email}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant="span">{t("nationalId")}</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography variant="span">{userInfo?.nationalId}</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant="span">{t("billingAddress")}</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography variant="span">
                        {userInfo?.address}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Button variant="outlined" onClick={handleClickOpen}>
                        {t("edit")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography variant="span">{t("dealership")}</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography variant="span">
                    {userInfo?.dealership?.name}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="span" className={classes.headerText}>
                    True Money Wallet
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography variant="span">{t("accountNumber")}</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography variant="span">
                        {userInfo?.tmnNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Button variant="outlined" onClick={handleClickOpen}>
                        {t("edit")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={handlePasswordDialogOpen}
                  >
                    {t("changePassword")}
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <EditAccountDialog
        onClose={handleClose}
        aria-labelledby="account-dialog-title"
        open={open}
      >
        <EditAccountDialogTitle id="account-dialog-title" onClose={handleClose}>
          <Typography variant="span" className={classes.headerText}>
            {`${t("edit")}${t("myAccount")}`}
          </Typography>
        </EditAccountDialogTitle>
        <DialogContent>
          <Formik
            validate={validate}
            onSubmit={onSubmit}
            initialValues={initialValues}
          >
            {({
              errors,
              isValid,
              isSubmitting,
              values,
              setFieldValue,
              handleChange,
            }) => (
              <Form>
                <Typography variant="h6" className={classes.headerText}>
                  {t("info")}
                </Typography>
                <div className={classes.textFieldContainer}>
                  <Grid container className={classes.formContainer} spacing={1}>
                    <Grid item xs={12}>
                      <InputField
                        name="thaiName"
                        value={values.thaiName}
                        label={t("nameInThai")}
                        placeholder={t("nameInThai")}
                        type="text"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <InputField
                        name="firstName"
                        value={values.firstName}
                        label={t("firstName")}
                        placeholder={t("firstName")}
                        type="text"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <InputField
                        name="lastName"
                        value={values.lastName}
                        label={t("lastName")}
                        placeholder={t("lastName")}
                        type="text"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputField
                        name="telephone"
                        value={values.telephone}
                        label={t("phoneNumber")}
                        placeholder={t("phoneNumber")}
                        type="telephone"
                        onChange={handleChange}
                      />
                      <InputField
                        name="nationalId"
                        value={values.nationalId}
                        label={t("nationalId")}
                        placeholder={t("nationalId")}
                        type="text"
                        inputProps={{ maxLength: 13 }}
                        onChange={handleChange}
                      />
                      <InputField
                        name="address"
                        value={values.address}
                        label={t("billingAddress")}
                        placeholder={t("billingAddress")}
                        type="text"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AddressFields
                        values={values}
                        onChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" className={classes.headerText}>
                        True Money Wallet
                      </Typography>
                      <div className={classes.textFieldContainer}>
                        <InputField
                          name="tmnNumber"
                          value={values.tmnNumber}
                          label={t("accountNumber")}
                          placeholder="082-123-1234"
                          type="telephone"
                          onChange={handleChange}
                        />
                      </div>
                      <div>
                        {size(errors) > 0 && (
                          <Alert severity="error" style={{ marginBottom: 8 }}>
                            <AlertTitle>Error</AlertTitle>
                            <ul style={{ paddingLeft: 0 }}>
                              {map(errors, (error, i) => (
                                <li key={`error-${i}`}>{error}</li>
                              ))}
                            </ul>
                          </Alert>
                        )}
                      </div>
                      <Button
                        variant="contained"
                        className={classes.primaryBtn}
                        type="submit"
                      >
                        {t("saveInfo")}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </EditAccountDialog>

      <EditAccountDialog
        onClose={handlePasswordDialogClose}
        aria-labelledby="change-password-dialog"
        open={openChangePasswordDialog}
      >
        <EditAccountDialogTitle
          id="change-password-dialog"
          onClose={handlePasswordDialogClose}
        >
          <Typography variant="span" className={classes.headerText}>
            {t("changePassword")}
          </Typography>
        </EditAccountDialogTitle>
        <DialogContent>
          <ChangePasswordForm
            handlePasswordDialogClose={handlePasswordDialogClose}
          />
        </DialogContent>
      </EditAccountDialog>
    </div>
  )
}

export default Account
