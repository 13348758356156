import React, { useEffect, useState } from "react"
import { makeStyles } from "@mui/styles"
import {
  Paper,
  Typography,
  Box,
  Chip,
  Tooltip,
  tooltipClasses,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import useDataprovider from "../../../hooks/useDataProvider"
import NumberFormat from "react-number-format"
import dayjs from "dayjs"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import useAuthProvider from "../../../hooks/useAuthProvider"
import { getPeriodQueryObject } from "../../../util/util"
import { useTranslation } from "react-i18next"
import { get, map, find } from "lodash"

const useStyles = makeStyles((theme) => ({
  logobmw: {
    width: "22px",
    marginRight: "5px",
    marginBottom: "-5px",
  },
  logomini: {
    width: "35px",
    marginRight: "5px",
    marginBottom: "-2px",
  },
  infoBox: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "&.MuiPaper-root": {
      borderRadius: 20,
      boxShadow: "0px 0px 6px 0px rgb(116 115 115 / 44%)",
    },
  },
  infoTextContainer: {
    marginTop: theme.spacing(3),
  },
  infoText: {
    "&.MuiTypography-root": {
      fontSize: "1.5rem",
      display: "inline",
      color: theme.palette.primary.main,
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
  },
  smallInfoText: {
    fontSize: 10,
  },
  currencyText: {
    fontSize: 16,
    paddingRight: theme.spacing(3),
  },
  emojiIcon: {
    width: 25,
    height: 25,
    marginLeft: 5,
    marginBottom: -5,
    color: "#757575",
  },
  selectControl: {
    "&.MuiOutlinedInput-root ": {
      borderRadius: 50,
      fontSize: "0.8rem",
      marginTop: theme.spacing(1),
      lineHeight: 1.7,
      "&.MuiSelect-select": {
        padding: "10px 15px",
      },
    },
  },
  dealership: {
    borderRadius: 50,
    border: "1px solid #c4c4c4",
    padding: "7px 20px",
  },
}))

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#757575",
    maxWidth: 120,
    border: "1px solid #757575",
    fontWeight: "300",
    borderRadius: 0,
    fontSize: 14,
  },
}))

export const LogoImage = (props) => {
  const { brand } = props
  const classes = useStyles()

  return brand === "BMW" ? (
    <img
      src="images/logo-bmw-small.png"
      alt="bmw"
      className={classes.logobmw}
    />
  ) : (
    <img
      src="images/logo-mini-small.png"
      alt="mini"
      className={classes.logomini}
    />
  )
}

export const TotalContract = (props) => {
  const classes = useStyles()
  const { totalContracts } = props
  const { t } = useTranslation()

  return (
    <Paper className={classes.infoBox}>
      <Typography variant="span">{t("totalContract")}</Typography>
      <div className={classes.infoTextContainer}>
        <Typography variant="h4" className={classes.infoText}>
          {totalContracts}
        </Typography>
      </div>
    </Paper>
  )
}

export const TotalSales = ({ totalSales }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Paper className={classes.infoBox}>
      <Typography variant="span">{t("totalSales")}</Typography>
      <div className={classes.infoTextContainer}>
        <Typography variant="h4" className={classes.infoText}>
          {totalSales}
        </Typography>
      </div>
    </Paper>
  )
}

export const TotalPoints = (props) => {
  const classes = useStyles()
  const { getTotalPoints } = useDataprovider()
  const [totalPoints, setTotalPoints] = useState()
  const { dealershipId, period } = props
  const { t } = useTranslation()

  useEffect(() => {
    const queryObj = {
      ...getPeriodQueryObject(period),
      dealershipId: dealershipId || "",
      validationState: "approved",
    }
    const query = new URLSearchParams(queryObj).toString()
    getTotalPoints(query).then(setTotalPoints)
  }, [getTotalPoints, period, dealershipId])

  return (
    <Paper className={classes.infoBox}>
      <Typography variant="span">{t("totalPoint")}</Typography>
      <div className={classes.infoTextContainer}>
        <Typography variant="h4" className={classes.infoText}>
          {totalPoints > 0 ? (
            <NumberFormat
              value={Math.trunc(totalPoints)}
              displayType={"text"}
              thousandSeparator={true}
            />
          ) : (
            0
          )}
        </Typography>
        <Typography variant="span" className={classes.smallInfoText}>
          1 pt = 1 THB
        </Typography>
      </div>
    </Paper>
  )
}

export const LeaderboardTotalPoints = ({ totalPoints }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Paper className={classes.infoBox}>
      <Typography variant="span">{t("totalPoint")}</Typography>
      <div className={classes.infoTextContainer}>
        <Typography variant="h4" className={classes.infoText}>
          {totalPoints > 0 ? (
            <NumberFormat
              value={Math.trunc(totalPoints)}
              displayType={"text"}
              thousandSeparator={true}
            />
          ) : (
            0
          )}
        </Typography>
        <Typography variant="span" className={classes.smallInfoText}>
          1 pt = 1 THB
        </Typography>
      </div>
    </Paper>
  )
}

export const LeaderboardRank = (props) => {
  const classes = useStyles()
  const { getLeaderBoardRank } = useDataprovider()
  const [leaderBoard, setLeaderBoard] = useState("")
  const { period, dealershipId } = props
  const { t } = useTranslation()

  useEffect(() => {
    if (dealershipId) {
      const queryObj = {
        ...getPeriodQueryObject(period),
        dealershipId: dealershipId || "",
      }
      const query = new URLSearchParams(queryObj).toString()

      getLeaderBoardRank(query).then(setLeaderBoard)
    } else setLeaderBoard("")
  }, [period, dealershipId, getLeaderBoardRank])

  return (
    <Paper className={classes.infoBox}>
      <Typography variant="span">{t("leaderboardRank")}</Typography>
      <div className={classes.infoTextContainer}>
        <Typography variant="h4" className={classes.infoText}>
          #{leaderBoard ? leaderBoard : "N/A"}
        </Typography>
      </div>
    </Paper>
  )
}

export const EffectiveRatio = (props) => {
  const classes = useStyles()
  const { getEffectiveRatio } = useDataprovider()
  const [effectiveRatio, setEffectiveRatio] = useState("")
  const { period, dealershipId } = props
  const { t } = useTranslation()

  useEffect(() => {
    const queryObj = {
      ...getPeriodQueryObject(period),
      dealershipId: dealershipId || "",
      validationState: "approved",
    }
    const query = new URLSearchParams(queryObj).toString()

    getEffectiveRatio(query).then(setEffectiveRatio)
  }, [getEffectiveRatio, dealershipId, period])

  return (
    <Paper className={classes.infoBox}>
      <Typography variant="span">{t("effectiveRatio")}</Typography>
      <LightTooltip title={t("effectiveRatioDesc")} placement="right">
        <InfoOutlinedIcon className={classes.emojiIcon} />
      </LightTooltip>
      <div className={classes.infoTextContainer}>
        <Typography variant="h4" className={classes.infoText}>
          {effectiveRatio ? Math.trunc(effectiveRatio) : 0}%
        </Typography>
      </div>
    </Paper>
  )
}

export const TotalPayout = ({ totalPayout }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Paper className={classes.infoBox}>
      <Typography variant="span">{t("totalPayout")}</Typography>
      <LightTooltip title={t("totalPayoutDesc")} placement="right">
        <InfoOutlinedIcon className={classes.emojiIcon} />
      </LightTooltip>
      <div className={classes.infoTextContainer}>
        <Typography variant="h4" className={classes.infoText}>
          <NumberFormat
            value={totalPayout}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={0}
          />
        </Typography>
        <Typography variant="span" className={classes.currencyText}>
          {t("thaiBaht")}
        </Typography>
      </div>
    </Paper>
  )
}

export const PreviousPayout = ({ prevPayout }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { paidAmount, paidDate } = prevPayout
  const payoutDate = dayjs(paidDate).format("DD MMM YYYY")
  return (
    <Paper className={classes.infoBox}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Typography variant="span">{t("previousPayout")}</Typography>
          <LightTooltip
            title={t("prevPayoutDesc", { date: payoutDate })}
            placement="right"
          >
            <InfoOutlinedIcon className={classes.emojiIcon} />
          </LightTooltip>
        </div>
        <Chip label={payoutDate} />
      </Box>
      <div className={classes.infoTextContainer}>
        <Typography variant="h4" className={classes.infoText}>
          <NumberFormat
            value={paidAmount}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={0}
          />
        </Typography>
        <Typography variant="span" className={classes.currencyText}>
          {t("thaiBaht")}
        </Typography>
        <Typography variant="span" className={classes.smallInfoText}>
          1 pt = 1 THB
        </Typography>
      </div>
    </Paper>
  )
}

export const NextPayout = ({ nextPayout }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Paper className={classes.infoBox}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {" "}
          <Typography variant="span">{t("nextPayout")}</Typography>
          <LightTooltip title={t("nextPayoutDesc")} placement="right">
            <InfoOutlinedIcon className={classes.emojiIcon} />
          </LightTooltip>
        </div>

        <Chip label="" style={{ background: "transparent" }} />
      </Box>
      <div className={classes.infoTextContainer}>
        <Typography variant="h4" className={classes.infoText}>
          <NumberFormat
            value={nextPayout}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={0}
          />
        </Typography>
        <Typography variant="span" className={classes.currencyText}>
          {t("thaiBaht")}
        </Typography>
      </div>
    </Paper>
  )
}

export const UserMenu = () => {
  const classes = useStyles()
  const { logout } = useAuthProvider()
  const [profile, setProfile] = useState()
  const { getProfile } = useDataprovider()
  const { t } = useTranslation()

  useEffect(() => {
    getProfile().then(setProfile)
  }, [getProfile])

  const handleChange = (event) => {
    if (event.target.value === "signout") logout()
  }

  return (
    <FormControl>
      <Select
        IconComponent={ExpandMoreIcon}
        defaultValue="1"
        className={classes.selectControl}
        onChange={handleChange}
      >
        <MenuItem value={1}>
          {profile?.firstName} {profile?.lastName}
        </MenuItem>

        <MenuItem value="signout">{t("signout")}</MenuItem>
      </Select>
    </FormControl>
  )
}

export const Dealership = () => {
  const classes = useStyles()
  const { getDealership } = useDataprovider()
  const [dealership, setDealership] = useState({})

  useEffect(() => {
    getDealership().then(setDealership)
  }, [getDealership])

  return (
    <div className={classes.dealership}>
      <LocationOnOutlinedIcon className={classes.emojiIcon} />
      {dealership?.name}
    </div>
  )
}

export const DealershipField = ({
  related = false,
  allowAllDealerships = false,
  handleChange = () => null,
}) => {
  const { getAllDealerships, getRelatedDealerships } = useDataprovider()
  const [dealerships, setDealerships] = React.useState([])
  const classes = useStyles()
  const { userInfo } = useAuthProvider()
  const currentDealership = get(userInfo, "dealership.id", null)
  const [defaultValue, setDefaultValue] = React.useState(currentDealership)
  const { t } = useTranslation()

  const options = map(dealerships, (value) => {
    return {
      value: value.id,
      label: value.name,
    }
  })

  const optionsWithAllDealerships = [
    {
      value: "all",
      label: t("allContracts"),
    },
    ...options,
  ]

  const optionsToShow = allowAllDealerships
    ? optionsWithAllDealerships
    : options

  useEffect(() => {
    const clearDefaultValue = (relatedDealerships) => {
      const isCurrentDealershipInRelatedDealerships = find(
        relatedDealerships,
        (dealership) => dealership.id === currentDealership
      )

      if (allowAllDealerships && !isCurrentDealershipInRelatedDealerships) {
        handleChange("")
        setDefaultValue("all")
      }

      if (!allowAllDealerships && !isCurrentDealershipInRelatedDealerships) {
        handleChange("")
        setDefaultValue("")
      }
    }

    const getData = async () => {
      if (related) {
        const relatedDealerships = await getRelatedDealerships()
        clearDefaultValue(relatedDealerships)
        setDealerships(relatedDealerships)
      } else getAllDealerships().then(setDealerships)
    }

    if (dealerships.length === 0) {
      getData()
    }
  }, [
    related,
    handleChange,
    getAllDealerships,
    getRelatedDealerships,
    allowAllDealerships,
    dealerships.length,
    currentDealership,
  ])

  const handleChangeDealership = (event) => {
    const dealershipId = event.target.value
    if (dealershipId === "all") handleChange("")
    else handleChange(dealershipId)
  }

  return (
    <FormControl>
      <Select
        variant="outlined"
        className={classes.selectControl}
        IconComponent={ExpandMoreIcon}
        defaultValue={defaultValue}
        onChange={handleChangeDealership}
        key={defaultValue}
      >
        {optionsToShow.map((option, key) => (
          <MenuItem value={option.value} key={key}>
            <LocationOnOutlinedIcon className={classes.emojiIcon} />
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
