import { createContext, useContext } from "react"
import axios from "axios"
import { get } from "lodash"

import config from "../config"
import useAuthProvider from "./useAuthProvider"

const initialState = {}

const DataProviderContext = createContext(initialState)

export const DataProvider = (props) => {
  const { token, logout } = useAuthProvider()
  let loggingOut = false

  const api = axios.create({
    baseURL: `${config.apiEndpoint}`,
    headers: { Authorization: `Bearer ${token}` },
  })

  api.interceptors.request.use(
    function (config) {
      if (!token) throw new axios.Cancel("Operation canceled.")
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  api.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 401 && !loggingOut) {
        loggingOut = true
        logout()
      }
      return error
    }
  )

  const getContractList = async (query) => {
    try {
      const result = await api.get(`/masterdata?${query}`).then((res) => {
        return res.data
      })
      return result
    } catch (err) {
      console.log(`cannot fetch contract list: ${err.message}`)
    }
  }

  const getEffectiveRatio = async (query) => {
    try {
      const result = await api.get(`/effectiveRatio?${query}`).then((res) => {
        return res.data.data.effectiveRatio
      })
      return result
    } catch (err) {
      console.log(`cannot fetch effective ratio: ${err.message}`)
    }
  }

  const getLeaderBoardRank = async (query) => {
    try {
      const result = await api.get(`/leaderboard/me?${query}`).then((res) => {
        return res?.data?.data?.rank
      })
      return result
    } catch (err) {
      console.log(`cannot fetch leaderboard rank: ${err.message}`)
    }
  }

  const getLeaderBoardList = async (query) => {
    try {
      const result = await api.get(`/leaderboard?${query}`).then((res) => {
        const leaderboardList = get(res, "data.data", [])
        const formattedLeaderboardList = leaderboardList.map((leaderboard) => ({
          userId: leaderboard.userId,
          salePerson: leaderboard.fullName,
          totalPoints: leaderboard.totalPoints,
          totalContracts: leaderboard.totalContracts,
        }))

        return {
          ...get(res, "data", {}),
          data: formattedLeaderboardList,
        }
      })
      return result
    } catch (err) {
      console.log(`cannot fetch leaderboard list: ${err.message}`)
    }
  }

  const getLeaderboardTotalPoints = async (query) => {
    try {
      const result = await api
        .get(`/leaderboard/totalpoints?${query}`)
        .then((res) => {
          return get(res, "data.data", 0)
        })
      return result
    } catch (err) {
      console.log(`cannot fetch total points: ${err.message}`)
    }
  }

  const getTotalPoints = async (query) => {
    try {
      const result = await api.get(`/rowpoints?${query}`).then((res) => {
        return res.data.data.totalPoints
      })
      return result
    } catch (err) {
      console.log(`cannot fetch total points: ${err.message}`)
    }
  }

  const getTransactionList = async (query) => {
    try {
      const result = await api.get(`/transactions?${query}`).then((res) => {
        return res?.data
      })
      return result
    } catch (err) {
      console.log(`cannot fetch transactions: ${err.message}`)
    }
  }

  const getTXAssocContracts = async (query) => {
    try {
      const result = await api
        .get(`/transactions/contracts?${query}`)
        .then((res) => {
          return res?.data
        })
      return result
    } catch (err) {
      console.log(`cannot fetch associated contracts: ${err.message}`)
    }
  }

  const getTotalPayout = async () => {
    try {
      const result = await api.get(`/transactions/totalPayout`).then((res) => {
        return res?.data?.data?.totalPayout
      })
      return result
    } catch (err) {
      console.log(`cannot fetch total payout: ${err.message}`)
    }
  }

  const getPreviousPayout = async () => {
    try {
      const result = await api.get(`/transactions/prevPayout`).then((res) => {
        return res?.data?.data?.prevPayout
      })
      return result
    } catch (err) {
      console.log(`cannot fetch previous payout: ${err.message}`)
    }
  }

  const getNextPayout = async () => {
    try {
      const result = await api.get(`/transactions/nextPayout`).then((res) => {
        return res?.data?.data?.nextPayout
      })
      return result
    } catch (err) {
      console.log(`cannot fetch next payout: ${err.message}`)
    }
  }

  const getProfile = async () => {
    try {
      const result = await api.get(`/profile`).then((res) => {
        return res.data.data
      })
      return result
    } catch (err) {
      console.log(`cannot fetch profile: ${err.message}`)
    }
  }

  const updateProfile = async (data) => {
    try {
      const result = await api.patch(`/profile`, data).then((res) => {
        if (res?.data?.data?.error === "NationalId is in use") {
          return {
            ...res.data.data,
            errorField: "nationalId",
          }
        }
        if (res?.data?.data?.error === "Email is in use") {
          return {
            ...res.data.data,
            errorField: "email",
          }
        }
        return res.data.data
      })
      return result
    } catch (err) {
      console.log(`cannot update profile: ${err.message}`)
    }
  }

  const submitBillingForm = async (data) => {
    try {
      const result = await api.patch(`/billing`, data).then((res) => {
        if (res?.data?.data?.error === "NationalId is in use") {
          return {
            ...res.data.data,
            errorField: "nationalId",
          }
        }
        return res.data.data
      })

      return result
    } catch (err) {
      console.log(`Cannot submit billing form: ${err.message}`)
    }
  }

  const updatePassword = async (data) => {
    try {
      const result = await api.patch(`/profile/password`, data).then((res) => {
        return res.data.data
      })
      return result
    } catch (err) {
      console.log(`cannot update password: ${err.message}`)
    }
  }

  const submitWallet = (data) => {
    return api
      .post(`/truemoney/update-wallet`, data)
      .then((res) => {
        return res.data
      })
      .then((response) => {
        console.log(`submitWallet::response`, response)
        const { success } = response.data
        return { success }
      })
  }

  const getDealership = async () => {
    try {
      const result = await api.get(`/dealership`).then((res) => {
        return res.data.data
      })
      return result
    } catch (err) {
      console.log(`cannot fetch dealership: ${err.message}`)
    }
  }

  const getAllDealerships = async () => {
    try {
      const result = await api.get(`/dealerships`).then((res) => {
        return res.data.data
      })
      return result
    } catch (err) {
      console.log(`cannot fetch all dealerships: ${err.message}`)
    }
  }

  const getRelatedDealerships = async () => {
    try {
      const result = await api
        .get("/dealerships/related")
        .then((res) => get(res, "data.data", []))

      return result
    } catch (err) {
      console.log(err.message)
    }
  }

  const getSalesManager = async (dealershipId) => {
    try {
      const result = await api
        .get(`/salesManager?dealershipId=${dealershipId}`)
        .then((res) => {
          return res.data.data
        })
      return result
    } catch (err) {
      console.log(`cannot fetch all dealerships: ${err.message}`)
    }
  }

  const submitNewUser = (data) => {
    return api
      .post(`/user`, data)
      .then((res) => res?.data)
      .then(({ data }) => data)
      .catch((error) => ({ error: error.message }))
  }

  const notifySalesManager = ({ token }) => {
    return api
      .post(`/notify/salesManager`, { token })
      .then((res) => res?.data)
      .then(({ data }) => data)
  }

  const notifyAdmin = ({ token }) => {
    return api
      .post("/notify/admin", { token })
      .then((res) => res?.data)
      .then(({ data }) => data)
  }

  return (
    <DataProviderContext.Provider
      value={{
        getContractList,
        getEffectiveRatio,
        getLeaderBoardRank,
        getLeaderBoardList,
        getLeaderboardTotalPoints,
        getTotalPoints,
        getTransactionList,
        getTXAssocContracts,
        getTotalPayout,
        getPreviousPayout,
        getNextPayout,
        getProfile,
        submitWallet,
        updateProfile,
        updatePassword,
        submitBillingForm,
        getDealership,
        getAllDealerships,
        getRelatedDealerships,
        getSalesManager,
        submitNewUser,
        notifySalesManager,
        notifyAdmin,
      }}
    >
      {props.children}
    </DataProviderContext.Provider>
  )
}

const useDataprovider = () => {
  const state = useContext(DataProviderContext)
  return state
}

export default useDataprovider
