import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import useDataprovider from "../../../hooks/useDataProvider"
import NumberFormat from "react-number-format"
import { makeStyles } from "@mui/styles"
import { LogoImage } from "./Components"
import { styled } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import { CircularProgress } from "@mui/material"
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridClasses,
} from "@mui/x-data-grid"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 4px",
    borderBottom: "1px solid #757575",

    "& .MuiTableHead-root": {
      borderBottom: "2px solid #757575",
    },
    "& .MuiTableCell-root": {
      border: "none",
    },
  },
  tbody: {
    "& tr": {
      "& .MuiTableCell-root": {
        border: "none",
        "&.MuiTablePagination-root": {
          border: "none",
        },
      },
    },
  },
  centerBox: {
    width: "100%",
    padding: "10px",
    textAlign: "center",
  },
  exportToolBar: {
    padding: 40,
  },
}))

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-cell": {
    whiteSpace: "initial",
  },
}))

const columns = [
  { field: "contractNumber", headerName: "Contract#", width: 100 },
  {
    field: "carModel",
    headerName: "Car Model",
    flex: 0.3,
    minWidth: 170,
    renderCell: (params) => (
      <>
        <LogoImage brand={params?.row?.carBrand || ""} />
        {params?.row?.carModel || ""}
      </>
    ),
  },
  { field: "salePerson", headerName: "Salesperson", flex: 0.1, minWidth: 150 },
  {
    field: "contractType",
    headerName: "Contract Type",
    flex: 0.1,
    minWidth: 150,
  },
  {
    field: "contractDate",
    headerName: "Contract Date",
    type: "date",
    width: 120,
    valueFormatter: (params) => {
      return dayjs(params.value).format("DD MMM YYYY")
    },
    valueGetter: (params) => {
      return dayjs(params.value).format("DD MMM YYYY")
    },
  },
  { field: "customerName", headerName: "Customer name", width: 170 },
  {
    field: "totalPoints",
    headerName: "Points",
    width: 120,
    renderCell: (params) => (
      <>
        <NumberFormat
          value={params?.row?.totalPoints}
          displayType={"text"}
          prefix={"+"}
          decimalScale={0}
        />
      </>
    ),
  },
]

const CustomToolbar = () => {
  return (
    <GridToolbarContainer
      className={gridClasses.toolbarContainer}
      style={{ padding: "12px 0 0 12px" }}
    >
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const AssociatedContractsList = ({ paymentDate }) => {
  const classes = useStyles()
  const { getTXAssocContracts } = useDataprovider()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [pageParams, setPageParams] = useState({
    pageNo: 0,
    limit: 5,
  })
  const [contracts, setContracts] = useState({
    data: [],
    totalItems: undefined,
  })

  useEffect(() => {
    if (paymentDate) {
      const { pageNo, limit } = pageParams
      const skip = pageNo * limit
      const query = { paymentDate, skip, limit }
      const queryStr = new URLSearchParams(query).toString()
      setIsLoading(true)
      getTXAssocContracts(queryStr).then((res) => {
        setContracts({
          data: res?.data,
          totalItems: res?.totalItems,
        })
        setIsLoading(false)
      })
    }
  }, [paymentDate, pageParams, getTXAssocContracts])

  const NestedLoader = () => {
    return (
      <div className={classes.centerBox}>
        <CircularProgress />
      </div>
    )
  }

  const ContractList = () => {
    return (
      <div style={{ height: 400, width: "100%" }}>
        <CustomDataGrid
          disableSelectionOnClick={true}
          paginationMode="server"
          rows={contracts?.data}
          rowCount={contracts?.totalItems}
          columns={columns}
          page={pageParams.pageNo}
          pageSize={pageParams.limit}
          rowsPerPageOptions={[pageParams.limit]}
          onPageChange={(newPage) => {
            setPageParams({ ...pageParams, pageNo: newPage })
          }}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    )
  }

  const DataNotFound = () => {
    return (
      <Box className={classes.centerBox}>
        <Typography variant="span" className={classes.headerText}>
          {t("noDataAvail")}
        </Typography>
      </Box>
    )
  }

  return (
    <div className={classes.root}>
      {isLoading ? (
        <NestedLoader />
      ) : contracts?.data?.length > 0 ? (
        <ContractList />
      ) : (
        <DataNotFound />
      )}
    </div>
  )
}

export default AssociatedContractsList
