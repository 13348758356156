import React, { useEffect, useState } from "react"
import { Box, Modal, Typography, CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"

const Loader = (props) => {
  const { title, width, isEnable, disableBackdrop } = props
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setIsOpen(isEnable)
  }, [isEnable])

  const boxProps = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width || 300,
    bgcolor: "background.paper",
    textAlign: "center",
    borderRadius: 2,
    p: 4,
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={(_, reason) => {
          const isForceClose =
            reason === "backdropClick" || reason === "escapeKeyDown"
          if (isForceClose && !disableBackdrop) {
            setIsOpen(false)
          }
        }}
      >
        <Box sx={boxProps}>
          <CircularProgress />
          <Typography variant="h6" component="h2" sx={{ mt: 2 }}>
            {title || t("loading")}
          </Typography>
        </Box>
      </Modal>
    </>
  )
}

export default Loader
