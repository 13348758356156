import React from "react"
import { makeStyles } from "@mui/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableFooter from "@mui/material/TableFooter"
import TablePagination from "@mui/material/TablePagination"
import TablePaginationActions from "../../../components/Pagination/TablePageActions"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import dayjs from "dayjs"
import NumberFormat from "react-number-format"
import { useTranslation } from "react-i18next"
import { LogoImage } from "./Components"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 4px",
    borderBottom: "1px solid #757575",

    "& .MuiTableHead-root": {
      borderBottom: "2px solid #757575",
    },
    "& .MuiTableCell-root": {
      border: "none",
    },
  },
  tbody: {
    "& tr": {
      "& .MuiTableCell-root": {
        border: "none",
        "&.MuiTablePagination-root": {
          border: "none",
        },
      },
    },
  },
}))

const ContractList = ({
  contracts,
  pageNumber,
  itemsPerPage,
  handleChangePage,
  handleChangeItemsPerPage,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    pageNumber > 0 ? Math.max(0, itemsPerPage - contracts?.data?.length) : 0

  return (
    <div className={classes.root}>
      {contracts?.data?.length > 0 ? (
        <>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("contractNumber")}</TableCell>
                  <TableCell>{t("carModel")}</TableCell>
                  <TableCell>{t("salesPerson")}</TableCell>
                  <TableCell>{t("contractType")}</TableCell>
                  <TableCell>{t("contractDate")}</TableCell>
                  <TableCell>{t("customerName")}</TableCell>
                  <TableCell>{t("points")}</TableCell>
                  <TableCell>{t("rewards")}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody className={classes.tbody}>
                {contracts?.data?.map((contract) => (
                  <TableRow key={contract.id}>
                    <TableCell component="th" scope="row">
                      {contract.contractNumber}
                    </TableCell>
                    <TableCell>
                      <LogoImage brand={contract.brand} />
                      {contract.carModel}
                    </TableCell>
                    <TableCell>{contract.salePerson}</TableCell>
                    <TableCell>{contract.contractType}</TableCell>
                    <TableCell>
                      {dayjs(contract.contractDate).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{contract.customerName}</TableCell>
                    <TableCell>
                      <NumberFormat
                        value={contract.totalPoints}
                        displayType={"text"}
                        prefix={"+"}
                        decimalScale={0}
                      />
                    </TableCell>
                    <TableCell>
                      <NumberFormat
                        value={contract.totalPoints}
                        displayType={"text"}
                        suffix={" THB"}
                        decimalScale={0}
                        thousandSeparator={true}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={8}
                    page={pageNumber}
                    count={contracts?.totalItems}
                    rowsPerPageOptions={[10, 25, 50]}
                    rowsPerPage={itemsPerPage}
                    onPageChange={(event, newPage) => {
                      handleChangePage(newPage)
                    }}
                    onRowsPerPageChange={(event) => {
                      const itemsPerPage = event.target.value
                      handleChangeItemsPerPage(itemsPerPage)
                      handleChangePage(0)
                    }}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Box sx={{ width: "100%", textAlign: "center" }}>
          <Typography variant="span" className={classes.headerText}>
            {t("noDataAvail")}
          </Typography>
        </Box>
      )}
    </div>
  )
}

export default ContractList
