import React, { useState } from "react"
import {
  Button,
  Typography,
  Grid,
  Container,
  Alert,
  AlertTitle,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import _ from "lodash"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import thai from "thai-data"
import dayjs from "dayjs"

import LogoSVG from "../../components/logoSvg"
import InputField from "../../components/InputField"
import AddressFields from "../../components/AddressFields"
import useAuthProvider from "../../hooks/useAuthProvider"
import useDataprovider from "../../hooks/useDataProvider"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100vh",

    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
    },
    "&.MuiContainer-root ": {
      width: "100%",
      padding: 0,
      maxWidth: "100%",
    },
  },
  content: {
    marginTop: theme.spacing(10),
  },
  headerContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    "& .MuiTypography-root": {
      fontSize: "2rem",
      fontWeight: 900,
      display: "inline",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.5rem",
      },
    },
  },
  contentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  textFieldContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  gridMarginRight: {
    [theme.breakpoints.up("md")]: {
      marginRight: theme.spacing(1),
    },
  },
  formContainer: {
    marginBottom: theme.spacing(10),
  },
}))

const Billing = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const classes = useStyles()
  const { userInfo, loadUserInfo } = useAuthProvider()
  const { notifySalesManager, notifyAdmin, getProfile, submitBillingForm } =
    useDataprovider()
  const { t } = useTranslation()
  const locations = thai.allData()
  const allowedZipcode = locations.map((location) => location.zipCode)

  const initialValues = {
    nationalId: "",
    address: "",
    province: "",
    district: "",
    sub_district: "",
    zipcode: "",
  }

  const validate = (values) => {
    const errors = {}
    const nationalIdRegex = /^[0-9]{13}$/
    const nationalIdValid = nationalIdRegex.test(values.nationalId)

    if (!values.address) _.set(errors, "address", `${t("addressError")}`)
    if (!values.sub_district)
      _.set(errors, "sub_district", `${t("subDistrictError")}`)
    if (!values.zipcode) _.set(errors, "zipcode", `${t("zipCodeError")}`)
    if (!_.includes(allowedZipcode, values.zipcode))
      _.set(errors, "zipcode", `${t("invalidZipCode")}`)
    if (!values.nationalId || !nationalIdValid)
      _.set(errors, "nationalId", `${t("nationalIdError")}`)

    return errors
  }

  const combineAddressValues = (values) => {
    const { address, province, district, sub_district, zipcode } = values
    const result = [address, province, district, sub_district, zipcode].join(
      ","
    )
    return result
  }

  const onSubmit = async (values, { setErrors }) => {
    setIsSubmitting(true)
    try {
      const { success, errorField } = await submitBillingForm({
        address: combineAddressValues(values),
        nationalId: `${values.nationalId}`,
      })

      if (success) {
        const userProfile = await getProfile()
        const salesManagerId = userProfile?.supervisor?.id || null

        const payload = {
          lang: "en",
          userId: userInfo.id,
          userCGName: userInfo.cognitoUserName,
          salesManagerId: salesManagerId,
          timestamp: dayjs().unix(),
        }
        const tokenStr = JSON.stringify(payload)
        const token = Buffer.from(tokenStr).toString("base64")

        const isAdmin = !salesManagerId

        const { status } = isAdmin
          ? await notifyAdmin({ token })
          : await notifySalesManager({ token })

        if (status) {
          loadUserInfo()
        } else {
          console.log("Notify Failed")
        }
      } else if (!success && errorField === "nationalId") {
        setErrors({ nationalId: t("nationalIdDuplicated") })
      }
      setIsSubmitting(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container maxWidth="xs">
      <Grid container className={classes.content}>
        <Grid item xs={12}>
          <LogoSVG />
          <div className={classes.headerContainer}>
            <Typography variant="h1">{t("billAndTax")}</Typography>
          </div>
        </Grid>
      </Grid>
      <Formik
        validate={validate}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ errors, isValid, values, handleChange, setFieldValue }) => (
          <Form>
            <Grid container className={classes.formContainer}>
              <Grid item xs={12}>
                <div className={classes.textFieldContainer}>
                  <InputField
                    name="address"
                    label={t("address")}
                    placeholder={t("addressPlaceHolder")}
                    type="text"
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <AddressFields
                  values={values}
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.textFieldContainer}>
                  <InputField
                    name="nationalId"
                    label={t("nationalId")}
                    placeholder="eg 1234567890123"
                    type="text"
                    inputProps={{ maxLength: 13 }}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  {_.size(errors) > 0 && (
                    <Alert severity="error" style={{ marginBottom: 8 }}>
                      <AlertTitle>Error</AlertTitle>
                      <ul style={{ paddingLeft: 0 }}>
                        {_.map(errors, (error, i) => (
                          <li key={`error-${i}`}>{error}</li>
                        ))}
                      </ul>
                    </Alert>
                  )}
                </div>
              </Grid>
              <Button
                variant="contained"
                disabled={isSubmitting || !isValid}
                className={classes.primaryBtn}
                type="submit"
              >
                {t("getStarted")}
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default Billing
