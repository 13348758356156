import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Typography, Box, Container } from "@mui/material"
import LogoSVG from "../../components/logoSvg"
import { makeStyles } from "@mui/styles"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
  },
  contentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  labelText: {
    "&.MuiTypography-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
}))

const NotFound = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Container maxWidth="xs" className={classes.root}>
      <Box className={classes.contentContainer}>
        <LogoSVG />
        <Typography variant="span" className={classes.labelText}>
          {t("notFound")}
        </Typography>
        <Button variant="contained" onClick={() => navigate(-1)}>
          {t("backHome")}
        </Button>
      </Box>
    </Container>
  )
}

export default NotFound
