import { Grid, Typography, Button } from "@mui/material"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"

import InputField from "../../../components/InputField"

const RejectionForm = ({ handleSubmit, candidateName }) => {
  const onSubmit = (values) => {
    const { reason } = values
    handleSubmit(reason)
  }
  const { t } = useTranslation()

  const initialValues = {
    reason: "",
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" mb={2}>
            {t("rejectFor", { name: candidateName })}
          </Typography>
        </Grid>
      </Grid>
      <Formik onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleChange }) => (
          <Form>
            <Grid container>
              <Grid item xs={12}>
                <InputField
                  name="reason"
                  label={t("rejectReason")}
                  placeholder={t("rejectReason")}
                  type="text"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Button variant="contained" color="error" type="submit">
              {t("reject")}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default RejectionForm
