import React from "react"
import { Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const ApprovalError = ({ message }) => {
  const { t } = useTranslation()
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">{t("approveError")}</Typography>
      </Grid>
      <Grid item xs={12} mt={2}>
        <Typography variant="subtitle1">
          {message || t("somethingWrong")}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ApprovalError
