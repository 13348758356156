import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  createContext,
} from "react"
import { useLocalStorage } from "react-use"
import jwt_decode from "jwt-decode"
import _ from "lodash"
import config from "../config"
import { ROLE } from "../constants"
import axios from "axios"
import dayjs from "dayjs"

export const isTokenValid = (token) => {
  try {
    const decoded = jwt_decode(token)
    console.log(
      `token expire in ${Math.round(decoded.exp - Date.now() / 1000) / 60}m`
    )
    if (decoded.exp > Date.now() / 1000) return true
    return false
  } catch (err) {
    return false
  }
}

const generateAuthorizationHeader = () => {
  const authorizationToken = btoa(
    `${config.authClientId}:${config.authClientSecret}`
  )

  return authorizationToken
}

const getTokenByRefreshToken = async (refresh_token) => {
  try {
    if (!refresh_token) return null

    const url = config.authEndpoint
    const authAuthorization = generateAuthorizationHeader()
    const options = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${authAuthorization}`,
      },
    }
    const data = {
      grant_type: "refresh_token",
      client_id: config.authClientId,
      refresh_token,
    }
    const body = _.map(data, (v, k) => `${k}=${v}`).join("&")

    const res = await fetch(`${url}/oauth2/token`, {
      method: "POST",
      ...options,
      body,
    })
    const json = await res.json()
    if (json.error) throw new Error(json.error)
    const { access_token, id_token } = json
    return { access_token, id_token }
  } catch (err) {
    console.log(err)
    switch (err.message) {
      case "invalid_grant": {
        // Code has already been used.
        return null
      }
      default:
        console.log(err.message)
        return null
    }
  }
}

const getTokenByCode = async (code) => {
  try {
    if (!code) return null

    const url = config.authEndpoint
    const authAuthorization = generateAuthorizationHeader()
    const options = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${authAuthorization}`,
      },
    }
    const data = {
      code,
      grant_type: "authorization_code",
      client_id: config.authClientId,
      redirect_uri: window.location.origin.replace(/\/$/, ""), // config.authRedirectURI,
      scope: "rewards/api.read rewards/api.write",
    }
    const body = _.map(data, (v, k) => `${k}=${v}`).join("&")

    const res = await fetch(`${url}/oauth2/token`, {
      method: "POST",
      ...options,
      body,
    })
    const json = await res.json()
    if (json.error) throw new Error(json.error)
    const { access_token, id_token, refresh_token } = json
    return { access_token, id_token, refresh_token }
  } catch (err) {
    console.log(err)
    switch (err.message) {
      case "invalid_grant": {
        // Code has already been used.
        return null
      }
      default:
        console.log(err.message)
        return null
    }
  }
}

const initialState = {
  code: null,
  user: null,
  userInfo: null,
  token: null,
  idToken: null,
  refreshToken: null,
  logout: null,
}

export const AuthProviderContext = createContext(initialState)

export const AuthProvider = (props) => {
  const urlParams = new URLSearchParams(window.location.search)
  const code = urlParams.get("code")

  const [userInfo, setuserInfo] = useState(null)

  const [token, setToken, removeToken] = useLocalStorage("bmw-rewards:token")
  const [idToken, setIdToken, removeIdToken] = useLocalStorage(
    "bmw-rewards:idtoken"
  )
  const [refreshToken, setRefreshToken, removeRefreshToken] = useLocalStorage(
    "bmw-rewards:refreshtoken"
  )

  const validToken = isTokenValid(token)

  const getUserInfo = _.memoize((token) => {
    try {
      const decoded = jwt_decode(token)
      return {
        id: decoded.sub,
        username: decoded.preferred_username || decoded["cognito:username"],
        email: decoded.preferred_email || decoded["email"],
        user_id: Number(decoded["custom:userId"]),
        isExpired: dayjs().isAfter(dayjs.unix(decoded.exp)),
      }
    } catch (err) {
      return null
    }
  })

  const user = getUserInfo(idToken)

  const getRedirectUrl = (roleId) => {
    switch (roleId) {
      case ROLE.NEW_USER:
        return "/information"
      case ROLE.SALES_CONSULTANT:
        return "/"
      case ROLE.SALES_MANAGER:
        return "/"
      case ROLE.SENIOR_SALES_MANAGER:
        return "/"
      case ROLE.GENERAL_MANAGER:
        return "/outlet"
      case ROLE.OWNER:
        return "/outlet"
      default:
        return ""
    }
  }

  const afterSignIn = useCallback(() => {
    if (user) {
      if (userInfo) {
        const roleId = `${userInfo.roleId}`
        const redirect_url = getRedirectUrl(roleId)
        window.location = redirect_url
      } else if (!user.user_id) {
        window.location = "/information"
      }
    }
  }, [userInfo, user])

  const clearTokens = useCallback(() => {
    removeToken()
    removeIdToken()
    removeRefreshToken()
  }, [removeToken, removeIdToken, removeRefreshToken])

  useEffect(() => {
    if (code) {
      getTokenByCode(code)
        .then(({ access_token, id_token, refresh_token }) => {
          if (access_token) {
            setToken(access_token)
            setIdToken(id_token)
            setRefreshToken(refresh_token)
          }
        })
        .catch(() => (window.location = "/login"))
    }
  }, [code, setToken, setIdToken, setRefreshToken])

  useEffect(() => {
    if (code && user) {
      afterSignIn()
    }
  }, [code, user, afterSignIn])

  useEffect(() => {
    if (!validToken && token) {
      clearTokens()
    }
  }, [validToken, token, clearTokens])

  const signInWithPopup = () => {
    const data = {
      client_id: config.authClientId,
      response_type: "code",
      redirect_uri: `${window.location.origin.replace(/\/$/, "")}`,
    }
    const qs = _.map(data, (v, k) => `${k}=${v}`).join("&")
    const url = `${config.authEndpoint}/login?${qs}&scope=email+openid+profile+rewards-new/api.write+rewards-new/api.read+aws.cognito.signin.user.admin`
    window.location.href = url
  }

  const signUpWithPopup = () => {
    const data = {
      client_id: config.authClientId,
      response_type: "code",
      redirect_uri: `${window.location.origin.replace(/\/$/, "")}`,
    }
    const qs = _.map(data, (v, k) => `${k}=${v}`).join("&")
    const url = `${config.authEndpoint}/signup?${qs}&scope=email+openid+profile+rewards-new/api.write+rewards-new/api.read+aws.cognito.signin.user.admin`
    window.location.href = url
  }

  const logout = useCallback(async () => {
    try {
      if (userInfo || token) {
        const tokenStr = localStorage.getItem("bmw-rewards:token")
        const token = tokenStr ? JSON.parse(tokenStr) : ""
        setuserInfo(null)
        clearTokens()
        const signOutRes = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_ENDPOINT}/signout`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const signOut = _.get(signOutRes, "data.data", "")
        console.log("[logout]:", signOut)
      }
    } catch (err) {
      console.log("[logout]: ", err)
    }
  }, [clearTokens, token, userInfo])

  const updateTokenByRefreshToken = useCallback(async () => {
    const tokens = await getTokenByRefreshToken(refreshToken)
    if (tokens) {
      const { id_token, access_token } = tokens
      console.log("Refreshed Tokens:", tokens)
      setToken(access_token)
      setIdToken(id_token)
    } else {
      clearTokens()
    }
  }, [clearTokens, refreshToken, setToken, setIdToken])

  const getProfile = useCallback(
    async (token) => {
      try {
        const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
        const result = await axios
          .get(`${API_ENDPOINT}/profile`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            return res.data.data
          })
          .catch((error) => {
            const statusCode = error.response.status
            if (statusCode === 401 || statusCode === 400) logout()
          })
        return result
      } catch (err) {
        console.log(`cannot fetch profile: ${err.message}`)
      }
    },
    [logout]
  )

  const loadUserInfo = () => {
    getProfile(token).then((res) => setuserInfo(res))
  }

  useEffect(() => {
    if (validToken && !userInfo && user && user.user_id) {
      getProfile(token).then((res) => setuserInfo(res))
    }
  }, [validToken, token, userInfo, user, getProfile])

  // useEffect(() => {
  //   // Refresh on token expire only	=> ✔✔✔
  //   // If token is not valid, then run logout flow => ✖✖✖
  //   const tokenExpired = _.get(user, "isExpired", false)
  //   if (tokenExpired && refreshToken) {
  //     try {
  //       console.log("Refreshing token")
  //       updateTokenByRefreshToken()
  //     } catch (err) {
  //       Sentry.captureMessage("Fail to renew access_token")
  //       logout()
  //     }
  //   }
  // }, [user, refreshToken, updateTokenByRefreshToken, logout])

  return (
    <AuthProviderContext.Provider
      value={{
        user,
        userInfo,
        token,
        idToken,
        refreshToken,
        code,
        isLogin: validToken,
        signInWithPopup,
        signUpWithPopup,
        updateTokenByRefreshToken,
        getRedirectUrl,
        loadUserInfo,
        logout,
      }}
    >
      {props.children}
    </AuthProviderContext.Provider>
  )
}

const useAuthProvider = () => {
  const state = useContext(AuthProviderContext)
  return state
}

export default useAuthProvider
