const config = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
  authClientId: process.env.REACT_APP_AUTH_CLIENTID,
  authClientSecret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
  authEndpoint: process.env.REACT_APP_AUTH_ENDPOINT,
  authRedirectURI: process.env.REACT_APP_AUTH_REDIRECTURI,
  trackingId: process.env.REACT_APP_GA_TRACKING_ID,
  environment: process.env.REACT_APP_ENV,
}

export default config
