import React from "react"
import { Button, Typography, Grid, Box } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useTranslation } from "react-i18next"

import useAuthprovider from "../hooks/useAuthProvider"
import RewardHeader from "../components/RewardHeader"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100vh",

    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
    },
    "&.MuiContainer-root ": {
      width: "100%",
      padding: 0,
      maxWidth: "100%",
    },
  },
  content: {
    width: "100%",
    height: "100vh",
  },
  headerContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    "& .MuiTypography-root": {
      fontSize: "2rem",
      fontWeight: 900,
      display: "inline",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.5rem",
      },
    },
  },
  description: {
    fontWeight: 300,
  },
  rightPanel: {
    height: "100%",
    color: "#fff",
    backgroundColor: theme.palette.secondary.main,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  leftPanel: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingLeft: "30%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10%",
    },
  },
  typographyRoot: {
    color: theme.palette.secondary.main,
  },
  primaryBtn: {
    "&.MuiButton-root": {
      display: "block",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(2),
    },
  },
}))

const LoginPage = () => {
  const classes = useStyles()
  const { signInWithPopup, signUpWithPopup } = useAuthprovider()
  const { t } = useTranslation()

  return (
    <div>
      <Grid container className={classes.content}>
        <Grid item xs={12} md={6}>
          <Box className={classes.leftPanel}>
            <RewardHeader />
            <Typography variant="span" className={classes.description}>
              {t("loginRewards")}
            </Typography>

            <Box sx={{ display: "flex" }}>
              <Button
                variant="contained"
                className={classes.primaryBtn}
                onClick={signInWithPopup}
              >
                {t("signIn")}
              </Button>
              <Button
                variant="outlined"
                className={classes.primaryBtn}
                onClick={signUpWithPopup}
              >
                {t("signUp")}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className={classes.rightPanel}>
            <img
              src="images/BMW/login-model.png"
              alt="BMW"
              style={{ width: "60%" }}
            />
            <div className={classes.headerContainer}>
              <Typography variant="h1">My BMW Rewards</Typography>
            </div>
            <Typography variant="span" className={classes.description}>
              Welcome to My BMW Rewards, Please login or signup to proceed.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default LoginPage
